<template>
  <div style="height: 100% !important; text-align: Center">
    <div class="vmuts-background" style="height: 35vh; line-height: 58px;position:relative">
      <a href="/#/home"><img style="position:absolute;right:32px;top:32px;height:18px;" src="./close.svg" /></a>
      <div
        class="antonio"
        style="
          height: 100%;
          display: flex;
          flex-direction:column;
          align-items: center;
          justify-content: center;
        "
      >
       <img
         style="height:92px"

          src="./vegan meetup ts_logo.png"
        />
        Informativa sui Cookies e Privacy
      </div>
    </div>
    <p
      class="info"
      style="margin: 10vh 10vw 0vh 10vw; width: calc(80vw) !important"
    >
      <strong>Cookie policy</strong>
    </p>
    <p
      class="info"
      style="margin: 5vh 10vw 0vh 10vw; width: calc(80vw) !important"
    >
      Questo sito fa uso di un cookie tecnico – il cc_cookie – con lo scopo di
      consentire il corretto funzionamento del sito agl* utent*. Si tratta di un
      cookie di funzionalità che permette di memorizzare le scelte effettuate
      dagl* utent* sul sito al fine di migliorare la fruizione da parte degl*
      stess*. Nello specifico, il cc_cookie memorizza le preferenze sui cookie.
      L’utilizzo di tale cookie è di carattere puramente anonimo, non vengono
      collezionate informazioni personali né per uso interno al sito né ad uso
      di terzi. Per l’installazione di tali cookise non è richiesto il
      preventivo consenso degl* utent* del sito. Oltre a modificare le
      impostazioni dei cookie sul sito, è possibile autorizzare, bloccare o
      rimuovere i cookie installati attraverso le impostazioni del proprio
      browser utilizzato. Per maggiori informazioni sulla gestione dei cookie,
      invitiamo a consultare le istruzioni del browser in uso.
    </p>

    <p
      class="info"
      style="margin: 10vh 10vw 0vh 10vw; width: calc(80vw) !important"
    >
      <strong>Privacy</strong>
    </p>

    <p
      class="info"
      style="margin: 5vh 10vw 0vh 10vw; width: calc(80vw) !important"
    >
      Il presente documento costituisce l’informativa prevista ai sensi
      dell’art. 13 del Regolamento (UE) 2016/679 del Parlamento Europeo e del
      Consiglio del 27 aprile 2016 (Regolamento Generale sulla Protezione dei
      Dati) nonché ai sensi dell’art. 13 del D. Lgs. 196/2003 (Codice in materia
      di protezione dei dati personali). Le informazioni contenute in questa
      pagina non si applicano a siti, app e contenuti di terzi di qualsiasi
      genere, neppure se accessibili dal sito cliccando su link in esso
      presenti.
    </p>
    <p
      class="info"
      style="margin: 5vh 10vw 0vh 10vw; width: calc(80vw) !important"
    >
      In questa Informativa sulla Privacy, spieghiamo come raccogliamo,
      utilizziamo e proteggiamo le informazioni personali degl* utent* che
      visitano il nostro sito. Utilizzando il nostro sito web, accetti le
      pratiche descritte di seguito.
    </p>

    <p
      class="info"
      style="margin: 5vh 10vw 0vh 10vw; width: calc(80vw) !important"
    >
      <strong>1. Raccolta delle informazioni</strong><br />
      Quando visiti il nostro sito, il nostro server può raccogliere
      automaticamente alcune informazioni, tra cui:
      <ul>
        <li> Indirizzo IP;
          </li>
                  <li>Tipo di
      browser;
          </li>
                <li> Sistema operativo;
          </li>
                  <li> Pagina web da cui sei arrivato;
          </li>
                  <li> Data e ora di accesso;
          </li>
          <li>  Cookies.
          </li>
          </ul>   
 
      Per quanto riguarda questi ultimi, utilizziamo un unico cookie per
      raccogliere e memorizzare il consenso degl* utent* riguardo all’uso dei
      cookies (vedi la nostra Informativa sui Cookies). Il cookie contiene un
      semplice identificatore che permette di determinare le tue preferenze
      sull’uso di cookies. Il tuo consenso viene registrato per migliorare la
      tua esperienza di navigazione sul nostro sito web.
    </p>

    <p
      class="info"
      style="margin: 5vh 10vw 0vh 10vw; width: calc(80vw) !important"
    >
      <strong>2. Uso delle informazioni</strong><br />
      Utilizziamo le informazioni raccolte, compreso il consenso al cookie, per
      i seguenti scopi: 
      <ul>

        <li>Fornire e personalizzare i contenuti del sito web;</li>
        <li>Analizzare l’uso del sito web e migliorare la sua usabilità;</li>
        <li>Rispondere alle tue domande o richieste; </li>
        <li> Mantenere la sicurezza del
      sito web e prevenire frodi o abusi. </li>
        <li> Condivisione delle informazioni Non
      condividiamo le informazioni personali raccolte con terze parti, a meno
      che non sia richiesto dalla legge o necessario per la prestazione dei
      servizi da te richiesti.</li>
      </ul>

     
    </p>

    <p
      class="info"
      style="margin: 5vh 10vw 0vh 10vw; width: calc(80vw) !important"
    >
      <strong>3. Sicurezza delle informazioni</strong><br />
      Facciamo del nostro meglio per proteggere le informazioni personali
      raccolte attraverso misure di sicurezza adeguate. Tuttavia, nessuna
      trasmissione di dati su Internet è sicura al 100%, pertanto non possiamo
      garantire la sicurezza delle informazioni durante la loro trasmissione o
      archiviazione.
    </p>

    <p
      class="info"
      style="margin: 5vh 10vw 0vh 10vw; width: calc(80vw) !important"
    >
      <strong>4. Diritti degl* utent* </strong><br/>
      Gl* utent* hanno il diritto di accedere, rettificare o cancellare le
      proprie informazioni personali in nostro possesso. Per esercitare uno di
      questi diritti o avere informazioni sulla nostra Informativa sulla
      Privacy, invitiamo a contattarci scrivendo a veganmeetupts@gmail.com.
    </p>

    <p
      class="info"
      style="margin: 5vh 10vw 0vh 10vw; width: calc(80vw) !important"
    >
      <strong>6. Cambiamenti all&#39;Informativa sulla Privacy</strong><br/>
      Ci riserviamo il diritto di modificare questa Informativa sulla Privacy in
      qualsiasi momento, pubblicando una versione aggiornata sul nostro sito. La
      data di aggiornamento più recente verrà indicata di seguito.
    </p>
    <p
      class="info"
      style="margin: 5vh 10vw 0vh 10vw; width: calc(80vw) !important"
    >
    Ultimo aggiornamento: 05/09/2023</p>
  </div>
</template>
<style>
.antonio {
  font-size: 44px !important;
}

.info {
  margin-top: 24px;
  line-height: 28px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: left !important;
}
</style>
<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  components: {},
};
</script>
