<template>
  <div style="overflow-x: hidden" data-scroll-container>
    <div id="hum" @click="triggermobilemenu" class="mobile">
      <img
        v-if="!mobilemenuopen"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAANUlEQVR4nO3VsQkAMAwDwd9/rGiwZIIUAYs0f6DaIBAGSQUBdjn5dXg1GpOunJM0Jn4nSTw4Ff6YkoE1i0QAAAAASUVORK5CYII="
      />
      <img
        v-if="mobilemenuopen"
        src="./close.svg"
        style="
          position: absolute;
          right: 12px !important;
          top: 12px;
          height: 22px;
        "
      />
    </div>
    <header style="z-index: 1023 !important">
      <div
        @wheel.prevent
        @touchmove.prevent
        @scroll.prevent
        id="mobilemenu"
        :style="
          mobilemenuopen
            ? 'height:calc(100vh - 72px);display:block;'
            : 'height:0px;display:none;'
        "
      >
        <div style="height: 96px; z-index: 99999999999999999999 !important">
          <img
            style="height: 96px"
            id="imglogomobile"
            onload="this.style.opacity=1"
            @click="$router.push('/home')"
            src="./vegan meetup ts_logo.png"
          />
        </div>
        <div class="menu-item">
          <router-link @click="triggermobilemenu" to="#cosafacciamo"
            >COSA FACCIAMO</router-link
          >
        </div>
        <div class="menu-item">
          <router-link @click="triggermobilemenu" to="#chisiamo"
            >CHI SIAMO</router-link
          >
        </div>
        <div class="menu-item">
          <router-link @click="triggermobilemenu" to="#contattaci"
            >CONTATTACI</router-link
          >
        </div>
        <div class="menu-item">
          <router-link @click="triggermobilemenu" to="#parlanodinoi"
            >PARLANO DI NOI</router-link
          >
        </div>

        <div class="menu-item">
          <a href="https://ko-fi.com/I2I5OJZXN" target="_blank">SOSTIENICI</a>
          <!-- <router-link @click="triggermobilemenu" to="#sostienici"
            >SOSTIENICI</router-link
          > -->
        </div>
        <div
          class="button button-main"
          style="width: 128px"
          black
          @click="openMappa"
        >
          MAPPA
        </div>
        <div style="display: flex; justify-content: center; padding: 12px">
          <div>
            <a href="https://www.instagram.com/veganmeetupts/" target="_blank"
              ><img
                style="width: 22px"
                src="./imgs/social/icon-instagram.svg"
                onload="this.style.opacity=1"
            /></a>
          </div>
          <div style="margin-left: 32px">
            <a
              href="https://www.facebook.com/veganmeetupTS/?locale=it_IT"
              target="_blank"
              ><img
                style="width: 12px"
                src="./imgs/social/icon-facebook.svg"
                onload="this.style.opacity=1"
            /></a>
          </div>
        </div>
      </div>

      <div
        id="logo"
        v-if="!mobilemenuopen"
        style="z-index: 99999999999999999999 !important"
      >
        <img
          id="imglogomobile"
          onload="this.style.opacity=1"
          @click="$router.push('/home')"
          src="./vegan meetup ts_logo.png"
        />
        <div class="nomobile">
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              text-align: center;
              margin: auto !important;
              padding: 12px 12px 8px;
              justify-content: center;
            "
          >
            <div class="nomobile">
              <a href="https://www.instagram.com/veganmeetupts/" target="_blank"
                ><img
                  style="width: 22px"
                  src="./imgs/social/icon-instagram.svg"
              /></a>
            </div>
            <div style="margin-left: 24px" class="nomobile">
              <a
                href="https://www.facebook.com/veganmeetupTS/?locale=it_IT"
                target="_blank"
                ><img style="width: 12px" src="./imgs/social/icon-facebook.svg"
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div
        id="header"
        style="position: absolute; top: 0px; width: 100vw; padding: 12px 0px"
      >
        <div style="display: flex; width: 256px"></div>
        <div
          style="
            display: flex;
            align-items: center;
            justify-content: flex-start;
          "
        >
          <div class="menu-item">
            <router-link to="#cosafacciamo">COSA FACCIAMO</router-link>
          </div>
          <div class="menu-item">
            <router-link to="#chisiamo">CHI SIAMO</router-link>
          </div>
          <div class="menu-item">
            <router-link @click="triggermobilemenu" to="#contattaci"
              >CONTATTACI</router-link
            >
          </div>
          <div class="menu-item">
            <router-link to="#parlanodinoi">PARLANO DI NOI</router-link>
          </div>

          <div class="menu-item">
            <a href="https://ko-fi.com/I2I5OJZXN" target="_blank">SOSTIENICI</a>
          </div>
        </div>

        <div
          style="
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            margin-right: 12px;
            position: relative;
            z-index: 1024 !important;
            width: 256px;
          "
        >
          <div class="button" hidden>EVENTI</div>
          <div
            class="button button-main"
            id="mapButton"
            black
            @click="openMappa"
          >
            MAPPA
          </div>
        </div>
      </div>
    </header>
    <section id="heromobile" class="mobile" style="position:relative;padding-top:0!important">
       <img
       
        src="../views/illu_new/illu-mobile/M-h120px-01.png"
        style="height: 120px; right: 0px; bottom: 40px; perspective: 10px;position:absolute;z-index:101"
      />
      <img
        id="heromobile-img-portrait"
        src="./vmutsimgs/brindisi-v3-PROP.jpg"
      />
      <img id="heromobile-img-landscape" src="./vmutsimgs/brindisi.jpg" />
      <div id="heromobile-backdrop"></div>
      <div
        style="
          text-align: left;
          position: absolute;
          bottom: 128px;
          left: 12px;
          z-index: 200;
        "
      >
        <div
          class="herotext animate__animated animate__fadeIn"
          style="color: white"
        >
          VEGAN MEETUP TRIESTE
        </div>
        <div
          class="vivatext animate__animated animate__fadeIn"
          style="color: white"
        >
          Viva veg e po bon!
        </div>
      </div>
    </section>
    <section
      class="vmuts-background nomobile"
      data-scroll-section
      style="position: relative"
    >
      <img
        class="illu nomobile illu-left"
        src="../views/illu_new/illu-desktop/D-h160px-01.png"
        style="
          height: 160px;
          left: 20vw;
          top: 20vh;

          perspective: 10px;
        "
      />
      <img
        class="illu nomobile illu-right"
        src="../views/illu_new/illu-desktop/D-h160px-02.png"
        style="height: 160px; left: 35vw; top: 75vh; perspective: 10px"
      />

      <div
        class="flex-row xl"
        style="height: 100vh; justify-content: space-around; width: 100%"
      >
        <div style="text-align: left">
          <div class="herotext-hero animate__animated animate__fadeIn">
            VEGAN MEETUP TRIESTE
          </div>
          <div class="vivatext-hero animate__animated animate__fadeIn">
            Viva veg e po bon!
          </div>
        </div>
        <div
          class="polaroid-hero nomobile"
          data-scroll
          data-scroll-velocity="2"
        >
          <img
            style="opacity: 0"
            loading="lazy"
            onload="this.style.opacity=1"
            src="./vmutsimgs/brindisi.jpg"
          />
        </div>
      </div>
    </section>
    <div id="cosafacciamo"></div>
    <section class="mobile-vmuts-background" style="position: relative">
      <img
        class="illu nomobile illu-right"
        src="../views/illu_new/illu-desktop/D-h160px-03.png"
        style="
          height: 160px;
          right: 5vw;
          top: 110vh;

          perspective: 10px;
        "
      />
      <img
        class="illu nomobile illu-left"
        src="../illu/VEGAN_illustration_5ra_0013_cornetto.png"
        style="height: 122px; left: 2vw; top: 100vh"
      />
             <img class="nodesktop"
       
        src="../views/illu_new/illu-mobile/M-h120px-02.png"
        style="height: 120px; right: 0px; bottom: -40px; perspective: 10px;position:absolute;z-index:101"
      />

      <div class="section-title">COSA FACCIAMO</div>
      <div
        style="margin: 24px auto !important"
        class="herotext-mission-desktop nomobile jack"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        La nostra missione è aumentare l’offerta a base vegetale a Trieste e
        dintorni del 300% entro il 2025.
      </div>
      <div
        style="margin: 12px auto !important"
        class="herotext-mission nodesktop jack"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        La nostra missione è aumentare l’offerta a base vegetale a Trieste e
        dintorni del 300% entro il 2025.
      </div>
      <div
        style="text-align: center; color: #d3a89a; margin: 0 auto !important"
        class="saturday jack animate__delay-hs"
        data-aos-anchor-placement="top-bottom"
        data-aos="slide-up"
        data-aos-duration="1000"
      >
        Come?
      </div>
      <!-- <div style="height: 32px"></div> -->
      <!-- //MEno spazio tra Come e 1 -->
    </section>
    <div class="nomobile" style="width: 100vw; position: relative">
      <section
        class="vmuts-background"
        style="position: relative; align-items: flex-start;width:100vw!important;max-width:100vw!important;height500px!important;padding:48px!important"
      >
        <!-- <img
          class="illu nomobile illu-left"
          src="../illu/VEGAN_illustration_5ra_0016_zucca.png"
          style="height: 72px; left: 10vw; top: 220vh"
        /> -->
        <div>
          <div
            class="an_fadeIn"
            style="margin: auto"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            <span class="saturday dotted">1.</span
            ><span class="saturday">Vegan</span>
            <span class="antonio" style="margin-left: 6px">meetup</span>
          </div>
          <div
            class="text"
            style="
              text-align: center !important;

              max-width: 640px !important;
              margin: auto;
              padding: 24px;
              margin-bottom: 12px;
            "
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            Picnic vegani, gratuiti e aperti a tutt* che rappresentano occasioni
            di socialità e condivisione per incoraggiare il networking e la
            creazione di una community locale.
          </div>
        </div>
        <!-- <div class="polaroid nomobile" style="width: 640px">
          <img src="./home_imgs/Gruppo di maschere 143.png" />
        </div> -->
        <img
          class="illu nomobile illu-right"
          src="../views/illu_new/illu-desktop/D-h160px-06.png"
          style="height: 160px; right: 10vw; top: 185vh; z-index: 1000"
        />
        <img
          class="illu nomobile illu-right"
          src="../views/illu_new/illu-desktop/D-h160px-08.png"
          style="height: 160px; right: 10vw; top: 220vh; z-index: 1000"
        />
        <img
          class="illu nomobile illu-left"
          src="../views/illu_new/illu-desktop/D-h160px-07.png"
          style="height: 160px; left: 13vw; top: 200vh; z-index: 1000"
        />
        <img
          class="illu nomobile illu-left"
          src="../views/illu_new/illu-desktop/D-h160px-05.png"
          style="height: 160px; left: 5vw; top: 155vh"
        />
        <!-- <div
        class="vmuts-background"
        style="
          top: 0px;
          height: 100%;
          position: absolute;
          z-index: -200 !important;
          left: -1024px;
          width: calc(100vw - 324px + 1024px);
        "
      ></div> -->

        <div
          class="an_fadeIn"
          style="margin-top: 32px"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          <span class="saturday dotted" style="font-size: 52px !important"
            >2.</span
          ><span class="antonio">#SostieniTrieste</span
          ><span class="saturday" style="margin-left: 6px">veg</span>
        </div>
        <div
          class="text"
          style="
            text-align: center !important;

            max-width: 640px !important;
            margin: auto;
            padding: 24px;
            margin-bottom: 12px;
          "
        >
          <div
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            Metaforicamente un faro per navigare le acque di una città ancora
            poco vegana; nella pratica una guida gratuita alle realtà
            vegan-friendly per guidare e incoraggiare le scelte alimentari e
            d’acquisto a base vegetale.
          </div>
        </div>
        <div style="display: flex; justify-content: center">
          <div
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
            class="button"
            style="width: 256px; margin-top: 12px; z-index: 1000000000000"
          >
            <a href="/#/map">VAI ALLA MAPPA</a>
          </div>
        </div>
        <div
          style="
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
          "
        >
          <div
            style="margin-top: 96px"
            class="fancy-title an_fadeIn"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            <span class="saturday dotted">3.</span
            ><span class="saturday">Vegan</span>
            <span class="antonio" style="margin-left: 6px">izzazioni</span>
          </div>
          <div
            class="text"
            style="
              text-align: center !important;
              max-width: 640px !important;
              margin: auto;
              padding: 24px;
              margin-bottom: 12px;
            "
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            Collaborazioni con realtà locali per avviare, ampliare o incentivare
            la loro offerta vegetale tramite l’organizzazione di eventi, la
            creazione di nuovi piatti o menù, la selezione di prodotti per la
            vendita.
          </div>
          <div style="height: 12px"></div>

          <div style="width: 100%; text-align: center">
            <div
              data-aos="slide-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
              class="button"
              style="
                box-sizing: border-box;
                width: 256px !important;
                margin: 0 auto;
              "
              @touchend="openCollaborazioni"
            >
              COLLABORAZIONI ATTIVE
            </div>
            <!-- <div
              class="sublink"
              data-aos="slide-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
            >
              <div class="highlight"></div>
              <a
                style="font-size: 19px"
                target="_blank"
                href="/#/map?collaborazioni=true"
                >COLLABORAZIONI ATTIVE
              </a>
              <div class="darrow"></div>
            </div> -->
          </div>
        </div>
      </section>
    </div>
    <section
      class="nodesktop"
      style="
        margin: 0px auto;
        text-align: center;
        padding: 48px 24px !important;
        box-sizing: border-box;
      "
    >
      <div style="text-align: center; padding: 0px 0px">
        <div
          class="an_fadeIn"
          style="background: white"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          <span class="saturday dotted">1.</span
          ><span class="saturday">Vegan</span>
          <span
            class="antonio"
            style="font-size: 32px !important; margin-left: 6px"
            >meetup</span
          >
        </div>
        <div
          class="text"
          style="background: white; font-weight: 600"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          Picnic vegani, gratuiti e aperti a tutt* che rappresentano occasioni
          di socialità e condivisione per incoraggiare il networking e la
          creazione di una community locale.
        </div>
      </div>
    </section>
    <div style="position: relative" class="nodesktop">
      <div
        class="vmuts-background"
        style="
          width: 100vw;
          height: 50%;
          position: absolute !important;
          bottom: 0;
          z-index: 0 !important;
        "
      ></div>
      <div class="polaroid-mobile nodesktop" style="margin: auto; z-index: 500">
        <img src="./vmutsimgs/valori.jpg" onload="this.style.opacity=1" />
      </div>
    </div>
    <!-- <section
      class="row-noflex vmuts-background nomobile"
      style="position: relative; padding-top: 96px !important; height: 100vh"
    >

    </section> -->

    <section
      class="vmuts-background nodesktop"
      style="position: relative; padding-top: 48px !important"
    >
      <div
        class="an_fadeIn"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        <span class="saturday dotted" style="font-size: 52px !important"
          >2.</span
        ><span class="antonio" style="font-size: 32px !important"
          >#SostieniTrieste</span
        >
        <span class="saturday" style="margin-left: 6px">veg</span>
      </div>
      <div
        class="text"
        style="text-align: center; margin: auto; padding: 24px"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        <div>
          Metaforicamente un faro per navigare le acque di una città ancora poco
          vegana; nella pratica una guida gratuita alle realtà vegan-friendly
          per guidare e incoraggiare le scelte alimentari e d’acquisto a base
          vegetale.
        </div>
      </div>
      <div style="display: flex; justify-content: center">
        <div
          class="button"
          style="margin-top: 24px"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          <a href="/#/map">VAI ALLA MAPPA</a>
        </div>
      </div>
      <div style="height: 12px"></div>
    </section>

    <!--TODO VEGANIZZAZIONI DESKTOP-->
    <!-- <div class="nomobile">
      <section class="flex-row" style="display: flex">

        <div class="polaroid nomobile" style="margin-left: 48px !important">
          <img src="./home_imgs/Gruppo di maschere 143.png" />
        </div>
      </section>
    </div> -->
    <section class="flex-row nodesktop">
      <div style="padding-top: 24px !important; margin: auto;position:relative">
                     <img class="nodesktop"
       
        src="../views/illu_new/illu-mobile/M-h120px-03.png"
        style="height: 120px; right: 0px; top: 80px; perspective: 10px;position:absolute;z-index:0"
      />
        <div
          class="fancy-title an_fadeIn"
          style="justify-content: center"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          <span class="saturday dotted">3.</span
          ><span class="saturday">Vegan</span>
          <span class="antonio" style="font-size: 32px !important"
            >izzazioni</span
          >
        </div>
        <div
          class="text"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          Collaborazioni con realtà locali per avviare, ampliare o incentivare
          la loro offerta vegetale tramite l’organizzazione di eventi, la
          creazione di nuovi piatti o menù, la selezione di prodotti per la
          vendita.
        </div>
        <div style="height: 48px"></div>
        <div
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
          class="button"
          style="
            box-sizing: border-box;
            width: 256px !important;
            margin: 0 auto;
          "
          @touchend="openCollaborazioni"
        >
          COLLABORAZIONI ATTIVE
        </div>
        <div style="height: 12px"></div>
        <div hidden style="margin-top: 24px; width: 100%; text-align: right">
          <div
            class="sublink"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            <div class="highlight"></div>
            <a target="_blank" href="/#/map?collaborazioni=true"
              >COLLABORAZIONI ATTIVE
            </a>
            <div class="darrow"></div>
          </div>
        </div>
      </div>
      <div class="polaroid nomobile">
        <img
          src="./home_imgs/Gruppo di maschere 143.png"
          onload="this.style.opacity=1"
        />
      </div>
    </section>
    <div id="chisiamo"></div>
    <section
      class="row-noflex nomobile"
      style="margin-bottom: 12px; position: relative"
    >
      <!-- <img
        class="illu nomobile illu-left"
        src="../views/illu_new/illu-desktop/D-h160px-09.png"
        style="height: 160px; left: 6vw; top: 225vh"
      /> -->
      <div
        class="section-title"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        CHI SIAMO
      </div>
      <div
        class="flex-row"
        style="justify-content: space-between; position: relative"
      >
        <!-- <img
          class="illu nomobile illu-left"
          src="../views/illu_new/illu-desktop/D-h160px-10.png"
          style="height: 160px; left: 10vw; top: 275vh"
        /> -->
        <img
          class="illu nomobile illu-right"
          src="../views/illu_new/illu-desktop/D-h160px-11.png"
          style="height: 160px; right: 10vw; top: 295vh"
        />
        <div class="polaroid nomobile">
          <img src="./vmutsimgs/chisiamo.jpeg" onload="this.style.opacity=1" />
        </div>
        <div style="margin-left: 48px">
          <p
            class="text"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            Nata nel 2020, dall’idea di Martina Pluda e Athina Krokos, Vegan
            Meetup Trieste è una giovane iniziativa, e la prima, con lo scopo di
            promuovere l'alimentazione a base vegetale e lo stile di vita vegano
            rendendolo più diffuso e accessibile, a Trieste e dintorni.
          </p>
          <br />
          <p
            class="text"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            Agiamo da catalizzatore per creare una community aperta e inclusiva
            di vegan*, vegetarian* e curios*. Facciamo da ponte con le realtà
            vegan-friendly locali per accelerare l'espansione dell'offerta
            vegetale nel nostro territorio.
          </p>
        </div>
      </div>
      <div
        class="vivatext jack"
        style="
          text-align: center !important;
          border: 0px solid red;
          margin: 48px auto;
          width: 640px;
          color: #373737;
        "
      >
        Se vuoi sostenere la nostra iniziativa, offrici un caffè virtuale!
      </div>
      <div
        style="margin: 24px 0px"
        class="jack"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        <a href="https://ko-fi.com/I2I5OJZXN" target="_blank">
          <img
            height="48"
            style="border: 0px; height: 48px"
            src="https://storage.ko-fi.com/cdn/kofi2.png?v=3"
            border="0"
            alt="Buy Me a Coffee at ko-fi.com"
        /></a>
      </div>
    </section>
    <section class="vmuts-background mobile">
      <div
        class="section-title"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        CHI SIAMO
      </div>
      <div style="text-align: center; padding: 0px; width: 100vw;position:relative;">

        <div>
          <p
            class="text"
            style="padding: 0px 24px"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            Nata nel 2020, dall’idea di Martina Pluda e Athina Krokos, Vegan
            Meetup Trieste è una giovane iniziativa, e la prima, con lo scopo di
            promuovere l'alimentazione a base vegetale e lo stile di vita vegano
            rendendolo più diffuso e accessibile, a Trieste e dintorni.
          </p>

          <p
            class="text"
            style="padding: 0px 24px"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
          >
            Agiamo da catalizzatore per creare una community aperta e inclusiva
            di vegan*, vegetarian* e curios*. Facciamo da ponte con le realtà
            vegan-friendly locali per accelerare l'espansione dell'offerta
            vegetale nel nostro territorio.
          </p>
        </div>
        <div class="polaroid-mobile nodesktop" style="margin: 32px auto">
          <img src="./vmutsimgs/chisiamo.jpeg" onload="this.style.opacity=1" />
        </div>
        <p
          class="text"
          style="
            text-align: center !important;
            border: 0px solid red;
            margin: 48px auto;
          "
        >
          Se vuoi sostenere la nostra iniziativa, offrici un caffè virtuale!
        </p>

        <div style="margin: 24px 0px">
          <a href="https://ko-fi.com/I2I5OJZXN" target="_blank">
            <img
              height="48"
              style="border: 0px; height: 48px"
              src="https://storage.ko-fi.com/cdn/kofi2.png?v=3"
              border="0"
              alt="Buy Me a Coffee at ko-fi.com"
          /></a>
        </div>
      </div>
    </section>
    <div class="nomobile">
      <section
        class="vmuts-background"
        style="
          position: relative !important;
          border: 0px solid red;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        "
      >
        <img
          class="illu nomobile illu-left"
          src="../views/illu_new/illu-desktop/D-h160px-12.png"
          style="height: 160px; left: 10vw; top: 305vh"
        />
        <img
          class="illu nomobile illu-right"
          src="../views/illu_new/illu-desktop/D-h160px-13.png"
          style="height: 160px; right: 10vw; top: 375vh"
        />
        <img
          class="illu nomobile illu-left"
          src="../views/illu_new/illu-desktop/D-h160px-14.png"
          style="height: 160px; left: 10vw; top: 355vh"
        />

        <!-- <div class="nomobile">
         <div
            class="vmuts-background nomobile"
            style="
              top: 0px;
              height: 100%;
              position: absolute;
              z-index: -1;
              left: 128px;
              width: calc(200vw - 0px);
            "
          ></div> 
        </div> -->
        <div class="valori-title" style="margin-top: 12px">
          <div
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
            class="herotext"
            style="font-weight: 400 !important; font-size: 41px"
          >
            I NOSTRI VALORI
          </div>
        </div>
        <div>
          <div style="border: 0px solid blue">
            <div
              class="vivatext"
              style="margin: 48px auto 0px"
              data-aos="slide-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
            >
              Inclusione e rispetto
            </div>
            <div
              class="text"
              data-aos="slide-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
              style="
                text-align: center !important;

                max-width: 640px !important;
                margin: auto;
                padding: 12px;
                margin-bottom: 0px;
              "
            >
              La nostra community è aperta e rispettosa delle persone con
              trascorsi e stili di vita diversi e crediamo che l’apertura al di
              fuori della “vegan bubble” ci porti più vicini all’ottenimento dei
              nostri obiettivi.
            </div>
          </div>
          <div>
            <div
              class="vivatext-pink"
              style="margin: 36px auto 0px"
              data-aos="slide-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
            >
              Empowerment e networking
            </div>
            <div
              data-aos="slide-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
              class="text"
              style="
                text-align: center !important;

                max-width: 640px !important;
                margin: auto;
                padding: 12px;
                margin-bottom: 24px;
              "
            >
              Incoraggiamo le persone nel loro viaggio verso il veganismo in
              modo positivo, costruttivo e accessibile, senza giudicare e
              aiutandole a costruire quella rete di supporto di cui hanno
              bisogno.
            </div>
            <div
              class="vivatext-blue an_fadeIn"
              style="margin: 36px auto 0px"
              data-aos="slide-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
            >
              Collaborazione e impatto locale
            </div>
            <div
              class="text"
              data-aos="slide-up"
              data-aos-anchor-placement="top-bottom"
              data-aos-duration="1000"
              style="
                text-align: center !important;
                max-width: 640px !important;
                margin: auto;
                padding: 12px;
                margin-bottom: 48px;
              "
            >
              Collaborando con altri gruppi e professionist* orientati alla
              stessa missione, e lavorando a stretto contatto con business
              locali, cambiamo l’orientamento cittadino rispetto al tema.
            </div>
          </div>
        </div>
      </section>
      <div style="position: relative; margin-bottom: 64px">
        <img
          class="illu nomobile illu-left"
          src="../views/illu_new/illu-desktop/D-h160px-15.png"
          style="height: 160px; left: 10vw; top: 385vh"
        />
        <img
          class="illu nomobile illu-left"
          src="../views/illu_new/illu-desktop/D-h160px-16.png"
          style="height: 160px; right: 12vw; top: 370vh"
        />
        <img
          class="illu nomobile illu-right"
          src="../views/illu_new/illu-desktop/D-h160px-17.png"
          style="height: 160px; right: 10vw; top: 425vh"
        />
        <div
          style="
            background-color: #efefe3;
            height: 152px;
            width: 100vw;
            position: absolute;
            z-index: -1;
          "
        ></div>
        <div style="height: 328px; width: 100vw"></div>
        <div
          class="polaroid nomobile"
          style="
            position: absolute;
            width: 480px;
            top: 0;
            left: calc(50vw - 240px);
          "
        >
          <img src="./vmutsimgs/valori.jpg" onload="this.style.opacity=1" />
        </div>
      </div>
    </div>
    <!-- <div class="nomobile">
      <section
        class="nomobile flex-row small"
        style="position: relative !important"
      >
        <img
          class="illu nomobile"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="2000"
          src="../illu/VEGAN_illustration_5ra_0012_prugne.png"
          style="height: 256px; right: 0vw; bottom: 2vh; z-index: 10000"
        />
        <div
          style="display:flex;flex-direction:column;!important;max-width:560px!important;width:560px!important"
        ></div>
      </section>
    </div>
    <div class="nomobile">
      <section class="nomobile flex-row" style="position: relative !important">
        <img
          class="illu nomobile"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="2000"
          src="../illu/VEGAN_illustration_5ra_0018_rucola1.png"
          style="height: 192px; left: 0vw; bottom: 0vh; z-index: 10000"
        />
        <div
          class="vmuts-background nomobile"
          style="
            top: 0px;
            height: 100%;
            position: absolute;
            z-index: -1;
            max-width: calc(200vw - 0px) !important;
            width: calc(200vw - 0px);
          "
        ></div>
        <div
          class="valore"
          style="position: absolute; right: 0; text-align: center"
        ></div>
      </section>
    </div> -->
    <!-- MOBILE -->

    <section
      class="nodesktop vmuts-background"
      style="padding: 48px 0px !important;position:relative;"
    >
             <img class="nodesktop"
       
        src="../views/illu_new/illu-mobile/M-h120px-05.png"
        style="height: 120px; left: 0px; top: -50px; perspective: 10px;position:absolute;z-index:0"
      />
                   <img class="nodesktop"
       
        src="../views/illu_new/illu-mobile/M-h120px-06.png"
        style="height: 120px; right: 0px; bottom: 0px; perspective: 10px;position:absolute;z-index:0"
      />
      <div
        class="antonio"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        <span>I NOSTRI VALORI</span>
      </div>

      <div class="valore-mobile">
        <div
          class="vivatext an_fadeIn"
          style="margin: 0px 0px 0px"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          Inclusione e rispetto
        </div>
        <div
          class="text"
          style="margin-top: 12px !important"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          La nostra community è aperta e rispettosa delle persone con trascorsi
          e stili di vita diversi e crediamo che l’apertura al di fuori della
          “vegan bubble” ci porti più vicini all’ottenimento dei nostri
          obiettivi.
        </div>
      </div>
      <div class="valore-mobile" style="margin-top: 0px">
        <div class="vivatext-pink an_fadeIn" style="margin-top: 0px">
          Empowerment e networking
        </div>
        <div
          class="text"
          style="margin-top: 12px !important"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          Incoraggiamo le persone nel loro viaggio verso il veganismo in modo
          positivo, costruttivo e accessibile, senza giudicare e aiutandole a
          costruire quella rete di supporto di cui hanno bisogno.
        </div>
      </div>
      <div class="valore-mobile" style="margin-top: 0px">
        <div
          class="vivatext-blue an_fadeIn"
          style="margin-bottom: 0px; margin-top: 0px"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          Collaborazione e impatto locale
        </div>
        <div
          class="text"
          style="margin-top: 12px !important"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          Collaborando con altri gruppi e professionist* orientati alla stessa
          missione, e lavorando a stretto contatto con business locali, cambiamo
          l’orientamento cittadino rispetto al tema.
        </div>
      </div>
    </section>
    <div style="height:96px" class="nomobile"></div>
    <!-- MOBILE -->
    <div id="contattaci"></div>
    <section
      class="row-noflex"
      style="
        position: relative;
        width: 100vw !important;
        max-width: 100vw !important;
      "
    >
      <img
        class="illu nomobile illu-left"
        src="../views/illu_new/illu-desktop/D-h160px-18.png"
        style="height: 160px; left: 10vw; top: 435vh"
      />
      <img
        class="illu nomobile illu-right"
        src="../views/illu_new/illu-desktop/D-h160px-21.png"
        style="height: 160px; right: 12vw; top: 495vh"
      />
      <div
        class="section-title"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        CONTATTACI
      </div>

      <div
        class="herotext-mission-desktop nomobile jack"
        style="
          margin: auto;
          text-align: center;
          width: 960px !important;
          max-width: 960px !important;
        "
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        Hai un’idea per una Trieste più vegana? Siamo sempre alla ricerca di
        idee innovative, o di scovare nuovi locali con opzioni vegane!
      </div>
      <div
        class="herotext-mission nodesktop jack"
        style="margin: 0px auto !important"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        Hai un’idea per una Trieste più vegana? Siamo sempre alla ricerca di
        idee innovative, o di scovare nuovi locali con opzioni vegane!
      </div>
      <div
        style="
          box-sizing: border-box;
          width: 192px;
          text-align: center !important;
          margin: auto;
        "
      >
        <div class="button" style="margin-top: 48px">
          <a
            href="mailto:veganmeetupts@gmail.com"
            data-aos="slide-up"
            data-aos-anchor-placement="top-bottom"
            data-aos-duration="1000"
            >SCRIVICI</a
          >
        </div>
      </div>
    </section>
    <div id="parlanodinoi"></div>

    <section
      class="nomobile"
      style="position: relative; width: 100vw !important; margin: 0px auto"
    >
      <img
        class="illu nomobile illu-left"
        src="../views/illu_new/illu-desktop/D-h160px-20.png"
        style="height: 160px; left: 10vw; top: 505vh"
      />
      <!-- <img
        class="illu nomobile illu-right"
        src="../views/illu_new/illu-desktop/D-h160px-21.png"
        style="height: 160px; right: 12vw; top: 55vh"
      /> -->
      <div
        class="vmuts-background"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
        style="
          width: calc(100vw - 0px) !important;
          margin: 0px auto;
          padding: 24px 0 !important;
          height: 432px !important;
        "
      >
        <div
          class="section-title"
          data-aos="slide-up"
          data-aos-anchor-placement="top-bottom"
          data-aos-duration="1000"
        >
          PARLANO DI NOI
        </div>
        <flickity
          ref="flickity-desktop"
          :options="flickityOptions"
          style="
            width: calc(100vw - 0px) !important;
            margin: auto;
            background: #efefe3;
            z-index: 5 !important;
          "
        >
          <div
            class="carousel-cell"
            v-for="(v, k) of parlanodinoi"
            :key="'_' + k"
          >
            <div
              class="voice"
              style="margin: 12px; text-align: left"
              @click="openLink(v.link)"
            >
              <div class="voice-title">
                <strong>{{ v.testata.toUpperCase() }}</strong> |
                {{ format(new Date(v.date), "dd/MM/yy") }}
              </div>
              <div class="voice-titolo">
                {{ v.titolo }}
              </div>
              <div>
                <a :href="formatWWWLink(v.link)" class="link" target="_blank"
                  >APRI</a
                >
              </div>
            </div>
          </div>
        </flickity>
      </div>
    </section>
    <div
      class="nodesktop"
      style="
        background: white;
        padding: 12px;
        box-sizing: border-box;
        height: 372px;
      "
    >
      <div
        class="section-title"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        PARLANO DI NOI
      </div>

      <flickity
        style="width: calc(100vw - 0px) !important; margin: auto"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
        ref="flickity-mobile"
        :options="flickityOptions2"
      >
        <div class="carousel-cell" v-for="(v, k) of parlanodinoi" :key="k">
          <div
            class="voice"
            style="margin: 12px; text-align: left"
            @click="openLink(v.link)"
          >
            <div class="voice-title">
              <strong>{{ v.testata.toUpperCase() }}</strong> |
              {{ format(new Date(v.date), "dd/MM/yy") }}
            </div>
            <div class="voice-titolo">
              {{ v.titolo }}
            </div>
            <div>
              <a :href="formatWWWLink(v.link)" class="link" target="_blank"
                >APRI</a
              >
            </div>
          </div>
        </div>
      </flickity>
    </div>

    <section class="row-noflex small nomobile" style="position: relative">
      <img
        class="illu nomobile illu-right"
        src="../illu/VEGAN_illustration_5ra_0007_foglia.png"
        style="height: 256px; right: 10vw; top: 820vh"
      />
      <div
        class="herotext-mission-desktop"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
        style="
          color: #373737;
          font-size: 40px;
          margin: 0 auto;
          width: 100%;
          text-align: center !important;
        "
      >
        Unisciti alla community
      </div>
      <div
        style="
          text-align: center;
          margin: 24px 0px;
          width: 960px;
          font-size: 19px;
        "
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        Seguici su instagram e facebook
      </div>
      <div class="social" style="margin: 0px; padding: 0">
        <div style="display: flex; justify-content: center; padding: 12px">
          <div>
            <a
              href="https://www.facebook.com/veganmeetupTS/?locale=it_IT"
              target="_blank"
            >
              <svg
                class="social-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 7.803 16.055"
              >
                <path
                  id="facebook"
                  d="M1.992,16.055V8.522H0V5.809H1.992V3.492C1.992,1.672,3.169,0,5.88,0A16.462,16.462,0,0,1,7.789.105L7.726,2.638S6.9,2.63,5.994,2.63c-.978,0-1.135.45-1.135,1.2V5.809H7.8L7.675,8.522H4.86v7.533H1.992"
                  fill="#333a3d"
                />
              </svg>

              <!-- <img class="social-icon" src="./imgs/social/icon-facebook.svg" style="height: 64px"
            /> -->
            </a>
          </div>
          <div>
            <a href="https://www.instagram.com/veganmeetupts/" target="_blank">
              <svg
                class="social-icon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16.038 16.032"
              >
                <path
                  id="instagram-filled"
                  d="M9.7,8.212A1.58,1.58,0,1,0,8.117,9.786,1.579,1.579,0,0,0,9.7,8.212ZM12.046.022H4.024A4,4,0,0,0,.016,4.012V5.124h5.9a3.807,3.807,0,0,1,4.406,0h5.733V4.012A4,4,0,0,0,12.046.022Zm1.623,3.8a.445.445,0,0,1-.445.443H11.847a.446.446,0,0,1-.445-.443V2.447A.445.445,0,0,1,11.847,2h1.376a.446.446,0,0,1,.445.443Zm-1.745,4.4a3.807,3.807,0,0,1-7.614,0A3.754,3.754,0,0,1,4.671,6.6H.016v5.462a4,4,0,0,0,4.008,3.99h8.021a4,4,0,0,0,4.008-3.99V6.6H11.562a3.755,3.755,0,0,1,.362,1.61Z"
                  transform="translate(-0.016 -0.022)"
                  fill="#333a3d"
                />
              </svg>

              <!-- <img
                class="social-icon"
                src="./imgs/social/icon-instagram.svg"
                style="height: 64px; margin-left: 48px;"
            />
             -->
            </a>
          </div>
          <div style="width: 32px"></div>
        </div>
      </div>
    </section>
    <section
      class="nodesktop vmuts-background"
      style="padding-bottom: 0px !important"
    >
      <p
        class="herotext-mission"
        style="margin: 0 auto !important"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        Unisciti alla community
      </p>
      <p
        style="width: 100%; text-align: center"
        class="text"
        data-aos="slide-up"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="1000"
      >
        Seguici su <br />Instagram e Facebook
      </p>
      <div class="social" style="margin: 0px">
        <div
          style="display: flex; justify-content: center; padding: 32px 0px 96px"
        >
          <div>
            <a
              href="https://www.facebook.com/veganmeetupTS/?locale=it_IT"
              target="_blank"
            >
              <img src="./imgs/social/icon-facebook.svg" style="height: 56px"
            /></a>
          </div>
          <div style="width: 72px"></div>
          <div>
            <a href="https://www.instagram.com/veganmeetupts/" target="_blank">
              <img src="./imgs/social/icon-instagram.svg" style="height: 56px"
            /></a>
          </div>
        </div>
      </div>
    </section>

    <div id="footer">
      <div style="width: 100%">
        <div>&copy; 2022 Vegan Meetup Trieste</div>
        <div style="height: 12px"></div>
        <div>
          Made with <span style="color: white">&hearts;</span> by
          <span style="color: white">Paolo</span> &
          <span style="color: white">Alice</span> - Illustrations by
          <span style="color: white">Petra</span> -<span>
            <a href="/#/privacy" style="color: #fff !important"
              >Privacy e Cookie</a
            ></span
          >
          -<span>
            <a href="/#/terms" style="color: #fff !important; z-index: 900000"
              >Termini e condizioni</a
            ></span
          >
        </div>
      </div>
      <div>
        <img
          @click="scrollTop"
          class="up"
          src="./up-arrow-svgrepo-com.svg"
          height="15"
          width="15"
        />
      </div>
    </div>
  </div>
</template>

 <script>
import "animate.css";
window.mobileAndTabletCheck = function () {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};
function isInViewport(el) {
  console.log(el)
  const rect = el.getBoundingClientRect();

  let top = rect.top;
  let bottom = rect.bottom;
  if (Array.from(el.classList).includes("polaroid")) {
    top = top - 256;
    bottom = bottom - 256;
  }

  return (
    top >= 0 &&
    rect.left >= 0 &&
    bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
import Ripple from "vue-ripple-directive";
// import Vue2TouchEvents from "vue2-touch-events";
Vue.directive("ripple", Ripple);
// Vue.use(Vue2TouchEvents);
// import AOS from "aos";
// import "aos/dist/aos.css"; // You can also use <link> for styles

// AOS.init();
import "animate.css";
// @ is an alias to /src
import Vue from "vue";
import Flickity from "vue-flickity";
import axios from "axios";
import { format } from "date-fns";
// import simpleParallax from "simple-parallax-js";
// import LocomotiveScroll from "locomotive-scroll";

export default {
  name: "Home",
  async beforeCreate() {},
  watch: {
    mobilemenuopen(open) {
      if (open) {
      } else {
        // document.body.scroll = "yes";
      }
    },
  },
  async mounted() {
          window.history.replaceState({}, document.title, "/");
    var lastScrollTop = 0;
    function doParallax(e) {
      let dir;

      var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"

      if (st > lastScrollTop) {
        dir = 1;
        // downscroll code
      } else if (st < lastScrollTop) {
        dir = -1;

        // upscroll code
      } // else was horizontal scroll
      //lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling

      let illu_right = document.querySelectorAll(".illu-right");
      let illu_left = document.querySelectorAll(".illu-left");

      Array.from(document.querySelectorAll(".an_fadeIn")).map((e) => {
        if (isInViewport(e))
          e.classList.add("animate__animated", "animate__fadeInUp");
        e.addEventListener(
          "animationend",
          () => {
            e.style.opacity = 1;
          },
          { once: true }
        );
      });
      Array.from(document.querySelectorAll(".button:not(.button-main)")).map(
        (e) => {
          if (isInViewport(e))
            e.classList.add("animate__animated", "animate__fadeIn");
          e.addEventListener(
            "animationend",
            () => {
              e.style.opacity = 1;
            },
            { once: true }
          );
        }
      );
      Array.from(document.querySelectorAll(".text")).map((e) => {
        if (isInViewport(e))
          e.classList.add("animate__animated", "animate__fadeInUp");
        e.addEventListener(
          "animationend",
          () => {
            e.style.opacity = 1;
          },
          { once: true }
        );
      });
      Array.from(document.querySelectorAll(".polaroid")).map((e) => {
        if (isInViewport(e))
          e.classList.add("animate__animated", "animate__fadeIn");
        e.addEventListener(
          "animationend",
          () => {
            e.style.opacity = 1;
          },
          { once: true }
        );
      });

      Array.from(document.querySelectorAll(".polaroid-mobile")).map((e) => {
        if (isInViewport(e))
          e.classList.add("animate__animated", "animate__fadeIn");
        e.addEventListener(
          "animationend",
          () => {
            e.style.opacity = 1;
          },
          { once: true }
        );
      });
      Array.from(document.querySelectorAll(".jack")).map((e) => {
        if (isInViewport(e))
          e.classList.add(
            "animate__animated",
            "animate__fadeIn",
            "animate__slow"
          );
        e.addEventListener(
          "animationend",
          () => {
            e.style.opacity = 1;
          },
          { once: true }
        );
      });
      function vh(percent) {
        var h = Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
        );
        return (percent * h) / 1000;
      }

      Array.from(illu_right).map((el) => {
        if (dir < 0)
          el.style.transform = `translate(0px,${
            vh(1) * 0.77 * (st - lastScrollTop)
          }px)`;
        if (dir > 0)
          el.style.transform = `translate(0px,${
            vh(1) * 0.77 * (-st + lastScrollTop)
          }px)`;
      });
      Array.from(illu_left).map((el) => {
        if (dir < 0)
          el.style.transform = `translate(0px,${
            vh(1) * 0.67 * (st - lastScrollTop)
          }px)`;
        if (dir > 0)
          el.style.transform = `translate(0px,${
            vh(1) * 0.67 * (-st + lastScrollTop)
          }px)`;
      });
    }

    // this.$router.replace({ query: null });

    //this.flickityOptions.prevNextButtons=false;

    this.$router.afterEach((to, from) => {
      window.history.replaceState({}, document.title, "/");
      this.mobilemenuopen = false;
      setTimeout(() => {
        var header = this.$el.querySelector("#header");
        var logo = this.$el.querySelector("#logo");
        var mapButton = this.$el.querySelector("#mapButton");
        var sticky = header.offsetTop;
        if (window.pageYOffset > sticky) {
          header.classList.add("sticky-new");
          logo.classList.add("logo-small");
          mapButton.classList.add("topButton");
        } else {
          header.classList.remove("sticky-new");
          logo.classList.remove("logo-small");
          mapButton.classList.remove("topButton");
        }
      }, 100);

      // var headermobile = this.$el.querySelector("#headermobile");
    });
    this.observeSections();

    var elem = this.$el.querySelector(".gallery");

    window.onscroll = function (e) {
      myFunction();
      window.requestAnimationFrame(doParallax);
    };

    // Get the header
    var header = this.$el.querySelector("#header");

    // var headermobile = this.$el.querySelector("#headermobile");
    var logo = this.$el.querySelector("#logo");
    var mapButton = this.$el.querySelector("#mapButton");
    // Get the offset position of the navbar

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
      var sticky = header.offsetTop;

      if (window.pageYOffset > sticky) {
        header.classList.add("sticky-new");
        logo.classList.add("logo-small");
        mapButton.classList.add("topButton");

        // headermobile.classList.add("stickymobile");
        //logo.style.height = "48px";
      } else {
        mapButton.classList.remove("topButton");
        header.classList.remove("sticky-new");

        logo.classList.remove("logo-small");

        // headermobile.classList.remove("stickymobile");
        //logo.style.height = "80px";
      }
    }

    //     setInterval(()=>{

    //   myFunction()

    // } ,120)
    this.parlanodinoi = (await axios.get("/vmuts/api/parlanodinoi")).data;
    this.$nextTick(async function () {
      // the magic
      console.log("render");

      setTimeout(() => {
        try {
          this.$refs["flickity-mobile"].rerender();
        } catch (e) {}
        let a = this.$refs["flickity-desktop"].rerender();
        console.log(a);

        // const images = document.querySelectorAll(".illu");
        // new simpleParallax(images, {
        //   delay: 0.9,
        //   transition: "cubic-bezier(0,0,0,7)",
        // });
        // console.log(images);

        // const polaroids = document.querySelectorAll(".polaroid");

        // new simpleParallax(polaroids, {
        //   delay: 0,
        //   transition: "cubic-bezier(1,0,0,3)",
        //   orientation: "up",
        //   scale: 1,
        //   overflow: true,
        // });

        // const scroll = new LocomotiveScroll({
        //   el: document.querySelector("[data-scroll-container]"),smooth:true
        // });
      });
    }, 100);

    let elements = document.querySelectorAll("[data-aos]");
    for (let i = 0; i < elements.length; i++) {
      //if ios remove the attribute
      if (/(iPad|iPhone|iPod)/g.test(navigator.userAgent)) {
        elements[i].removeAttribute("data-aos");
      }
    }
  },
  methods: {
    openLink(e) {
      if (e) {
        let link =
          "https://" +
          e.replace("https://", "").replace("http://", "").replace(/\/$/, "");
        window.open(link, "_blank");
      }
    },
    formatWWWLink(e) {
      if (e)
        return (
          "https://" +
          e.replace("https://", "").replace("http://", "").replace(/\/$/, "")
        );
      return "";
    },
    openCollaborazioni() {
      let a = document.createElement("a");
      a.href = "/#/map?collaborazioni=true";
      a.click();
    },
    format,
    triggermobilemenu() {
      console.log("woooo");
      this.mobilemenuopen = !this.mobilemenuopen;
      var header = this.$el.querySelector("#header");

      // var headermobile = this.$el.querySelector("#headermobile");
      var logo = this.$el.querySelector("#logo");
      var mapButton = this.$el.querySelector("#mapButton");
      // Get the offset position of the navbar
      var sticky = header.offsetTop;

      console.log(sticky);
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    observeSections() {
      try {
        this.sectionObserver.disconnect();
      } catch (error) {}

      const options = {
        rootMargin: "0px 0px",
        threshold: 0.25,
      };
      this.sectionObserver = new IntersectionObserver(
        this.sectionObserverHandler,
        options
      );

      // Observe each section
      const sections = document.querySelectorAll(".anchor");
      sections.forEach((section) => {
        this.sectionObserver.observe(section);
      });
    },
    sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          history.pushState(
            {},
            null,
            window.location.origin + "/#/home" + `#${sectionId}`
          );
          //window.location.hash = `#${sectionId}`;
          // Push sectionId to router here
          //this.$router.replace({  hash: `#${sectionId}` });
        }
      }
    },
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
    openMappa() {
      let a = document.createElement("a");
      a.href = "/#/map";
      a.click();
    },
  },
  data() {
    return {
      parlanodinoi: [],
      mobilemenuopen: false,
      sectionObserver: null,
      flickityOptions: {
        initialIndex: 0,

        contain: true,
        prevNextButtons: true,
        pageDots: true,

        // any options from Flickity can be used
      },
      flickityOptions2: {
        initialIndex: 0,

        contain: true,
        prevNextButtons: false,
        pageDots: true,

        // any options from Flickity can be used
      },
    };
  },
  components: {
    Flickity,
  },
};
</script>
<style>
/* .voice:hover {

} */
.voice-titolo {
  height: 104px;
}
#mapButton {
  opacity: 1 !important;
}
.illu {
  position: fixed;
  background: transparent;
  z-index: 2 !important;
}
.fancy-title {
  display: flex;
  align-items: center;
  text-align: center !important;
}

header {
  position: fixed;
}
section {
  padding: 32px 0px !important;
  width: 100vw;
  text-align: center;
  background-attachment: fixed;
}

/*.flickity-prev-next-button.previous {
  left: -40px;
}
.flickity-prev-next-button.next {
  right: -40px;
  z-index: 100;
}*/

html {
  scroll-behavior: smooth;
}
.dotted {
  margin-right: 12px !important;
}
:root {
  --vmuts-background: #efefe3;
  --main-width: 960px;
  --page-height: 640px;
  --footer-background: #333a3d;
  --header-background: transparent;
  --footer-height: 96px;
  --header-height: 64px;
  --menu-font-family: Roboto;
  --menu-font-size: 13px;
  --menu-item-width: 128px;
  --hover-menu-color: red;
  --header-height-mobile: 40px;
}
.vmuts-background {
  z-index: 0 !important;
  /* background: var(--vmuts-background); */
}
.hero-text {
  max-width: 760px;
  font-size: 37px !important;
  font-weight: 600 !important;
  line-height: 57px;
}
.section-title:before,
.section-title:after {
  background-color: #474747!;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 24px;
  margin: 0px 12px;
}

.flex-row > div {
  flex-grow: 1;

  max-width: 480px;
}

.herotext {
  font-size: 56px;
  line-height: 72px;
  font-weight: 400 !important;
  font-family: AntonioBold;
}
.herotext-hero {
  font-size: 56px;
  line-height: 72px;
  font-weight: 400 !important;
  font-family: AntonioBold;
}
.polaroid {
  height: 480px;
  width: 480px;
  background: #fff;
  z-index: 101 !important;
  padding: 1rem;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.polaroid > img {
  object-fit: cover;
  aspect-ratio: 1/1;
  object-fit: cover;
  max-width: 100%;
  height: calc(100% - 0px) !important;
}
.valori-title {
  text-transform: capitalize;
  font-size: 29px;
  font-weight: 600;
}
.valore {
  padding: 48px;

  font-size: 21px;
  width: 760px !important;

  max-width: 640px !important;
  width: 640px;
}
.valore-mobile {
  font-size: 19px;
  margin-top: 24px;
  padding: 24px;
  width: 100vw;
}
.small {
  min-height: 396px !important;
}

.sticky-new {
  position: fixed;
  z-index: 10000000;
  width: 100%;
  /* -webkit-box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
  box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1); */
}

/* Small devices such as large phones (640px and up) */

/* Medium devices such as tablets (768px and up) */

@media only screen and (max-width: 1024px) {
  .nomobile {
    display: none !important;
  }
  .nodesktop {
    display: block !important;
  }
  .desktop {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }

  .section-title {
    width: 100%;
    font-weight: 600;

    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    letter-spacing: 2px;
    margin-top: 32px;
    margin-bottom: 32px;
    text-transform: uppercase;
  }
  .vivatext-hero {
    margin-top: 12px;
    font-family: Saturday;
    font-size: 48px;
    color: #808d4b;
  }
  .vivatext {
    margin-top: 12px;
    font-family: Saturday;
    font-size: 40px;
    color: #808d4b;
  }
  .vivatext-pink {
    margin-top: 12px;
    font-family: Saturday;
    font-size: 40px;
    color: #d3a89a;
  }
  .vivatext-blue {
    color: #ba8ca4 !important;
    margin-top: 12px;
    font-family: Saturday;
    font-size: 40px;
  }
  .logo-small {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 64px !important;
    background: red;
    margin: auto;
    height: 64px !important;
  }
  .logo-small > img {
    height: 32px !important;
    margin: auto;
  }
  .half-height {
    height: 100vh !important;
    padding: 64px 0px !important;
  }
  .half-half-height {
    height: 50vh !important;
    padding: 64px 0px !important;
  }
  #heromobile {
    position: relative;
    font-family: Poppins !important;
    color: white !important;
    z-index: -1 !important;
    height: 100vh;
    overflow-x: hidden;
    width: 100vw;
  }
  #heromobile-img {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    object-fit: none;
  }
  #heromobile-backdrop {
    position: absolute;
    opacity: 0.5;
    height: 100vh;
    top: 0px;
    left: 0px;
    z-index: 100 !important;
    width: 100vw;
    background: #373737;
  }
  header > #logo {
    z-index: 2000000000 !important;
    background: white;
    height: 142px;

    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    width: 142px;
    background: white;
    position: relative;
    text-align: center;
    left: 32px !important;
  }

  .mobile-vmuts-background {
    background: var(--vmuts-background) !important;
  }
  .herotext-mission {
    width: 80vw !important;
    min-width: 80vw !important;
    max-width: 80vw !important;
    text-align: center;
    font-size: 28px !important;
    line-height: 48px;
    z-index: 150 !important;
    text-align: center;
    font-weight: 400;
    margin: 96px auto !important;
  }
  #header:not(.map_stuff) {
    display: none !important;
  }

  .flex-row {
    display: flex !important;
    flex-direction: column;
    width: 80vw !important;
    min-width: 80vw !important;
    max-width: 80vw !important;
    min-height: 240px;
  }
  .row-noflex {
  }

  .text {
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    opacity: 0;
    margin-top: 24px;
    line-height: 36px !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    text-align: center !important;
  }
 
  .polaroid-mobile > img {
    object-fit: cover;
    aspect-ratio: 1/1;
    object-fit: cover;
    width: 100%;
    height: auto;
  }

  #logo > img {
    height: 142px;
  }
  .button {
    position: relative;
    z-index: 1024 !important;
    font-family: Poppins;
    font-size: 16px;
    min-width: 148px;
    font-weight: 400 !important;
    text-align: center;
    border: 3px solid #333a3d;

    padding: 12px;
    color: #333a3d;
    /* height: var(--button-height);
  width: var(--button-width); */
    cursor: pointer;
  }
  .social {
    margin-top: 48px;

    width: 100%;
  }
  .nodesktop {
    display: none;
  }
  .fancy-title {
    display: flex;
    align-items: center;
    text-align: center !important;
  }
  .vivatext-hero {
    margin-top: 12px;
    font-family: Saturday;
    font-size: 40px;
    color: #808d4b;
  }
  .carousel-cell {
    margin-left: 12px;
    box-sizing: border-box;
  }
  .voice {
    box-shadow: rgba(0, 0, 0, 0.35) 1px 5px 15px;
    font-size: 16px;
    width: calc(75vw) !important;
    background: rgba(0, 0, 0, 0.005) !important;
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box;
    z-index: 4 !important;

    height: 192px !important;
  }
  .menu-item {
    margin-top: 16px !important;
  }
  .button-main {
    opacity: 1 !important;
  }
}
@media only screen and (max-width: 1024px) and (orientation: portrait) {
   .polaroid-mobile {
    position: relative;
    z-index: 5 !important;
    top: 0px;
    left: 0px;
    height: calc(100vw - 48px);
    width: calc(100vw - 48px);
    z-index: 200000;
    background: #fff;
    padding: 12px;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }
  #heromobile-img-landscape {
    display: none;
  }
  #heromobile-img-portrait {
    display: block;
  }
}
@media only screen and (max-width: 1024px) and (orientation: landscape) {
   .polaroid-mobile {
    position: relative;
    z-index: 5 !important;
    top: 0px;
    left: 0px;
    height: calc(100vh - 48px);
    width: calc(100vh - 48px);
    z-index: 200000;
    background: #fff;
    padding: 12px;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }
  #heromobile-img-landscape {
    display: block;
    height:100vh;
    width:100%;
    object-fit:cover
    
  }
  #heromobile-img-portrait {
    display: none;
  }
}

/* Large devices such as laptops (1024px and up) */
@media only screen and (min-width: 1024px) {
  .menu-item {
    margin-top: 0 !important;
  }
  .topButton {
    position: fixed;
    top: 0px !important;
    right: 0px;
    height: 64px !important;
    line-height: 64px;
    /* line-height: 64px !important; */
    padding: 0px !important;
  }
  .sticky-new {
    position: fixed;
    z-index: 10000000;
    width: 100%;
    -webkit-box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
    -moz-box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
    box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
    background: white !important;
    height: 64px;
  }

  header > .logo-small {
    z-index: 2000000000 !important;
    background: white;
    height: 142px;
    box-shadow: none !important;

    width: 142px;

    position: relative;
    text-align: center;
    left: 32px !important;
  }

  .logo-small {
  }

  .polaroid-hero {
    height: 480px !important;
    width: 480px !important;
    background: #fff;
    z-index: 120 !important;
    padding: 12px !important;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    overflow: hidden;

    max-height: calc(100vh - 132px) !important;
  }
  .polaroid-hero > img {
    object-fit: cover;
    aspect-ratio: 1/1;
    object-fit: cover;
    width: calc(100% - 0px);

    height: calc(100% - 0px) !important;
  }
  .herotext-hero {
    position: absolute;
    left: 64px;
    bottom: calc(50vh - 12px);
  }
  .vivatext-hero {
    bottom: calc(50vh - 204px);

    position: absolute;
    left: 104px;
    transform: rotate(-15deg);

    font-family: Saturday;
    font-size: 64px;
    color: #808d4b;
  }

  .nomobile {
    display: block !important;
  }
  .nodesktop {
    display: none !important;
  }
  .desktop {
    display: block !important;
  }
  .mobile {
    display: none !important;
  }
  .section-title {
    width: 100%;
    font-weight: 500 !important;

    text-align: center;
    font-family: Poppins;
    font-size: 17px !important;
    letter-spacing: 2px;
    margin-top: 32px;
    margin-bottom: 32px;
    text-transform: uppercase;
  }
  .button {
    font-family: Poppins;
    font-size: 16px;
    min-width: 148px;
    font-weight: 400 !important;
    text-align: center;
    border: 3px solid #333a3d;
    z-index: 10000000000 !important;
    padding: 24px;
    color: #333a3d;
    /* height: var(--button-height);
  width: var(--button-width); */
    cursor: pointer;
  }
  .social {
    margin-top: 48px;

    width: 100%;
  }
  .vivatext {
    margin-top: 12px;
    font-family: Saturday;
    font-size: 52px;
    color: #808d4b;
  }
  .vivatext-pink {
    margin-top: 12px;
    font-family: Saturday;
    font-size: 52px;
    color: #e8d1cb;
  }
  .vivatext-blue {
    color: #ba8ca4 !important;
    margin-top: 12px;
    font-family: Saturday;
    font-size: 52px;
  }
  .logo-small {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 64px !important;
  }
  .logo-small > img {
    height: 64px !important;
  }

  .half-height {
    height: 100% !important;
    padding: 128px 0px !important;
  }
  .half-half-height {
    height: 100% !important;
    padding: 0px 0px !important;
  }
  #logo > img {
    height: 142px;
  }
  header > #logo {
    z-index: 2000000000 !important;
    height: 204px;

    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    width: 142px;
    background: white;
    position: relative;
    left: 64px;
  }
  .nodesktop {
    display: none !important;
  }
  .text {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
    max-width: 396px;
    letter-spacing: 1.2px;
    line-height: 31px !important;
    font-size: 17px !important;
    text-align: left !important;
    font-weight: 400 !important;
  }
  .flex-row {
    align-items: center;
    display: flex;
    flex-direction: row;
    min-height: 480px;
    max-width: 960px;
    width: 960px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
  }
  .row-noflex {
    min-height: 480px;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  .herotext-mission-desktop {
    font-size: 76px;
    max-width: 792px;
    min-width: 792px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-size: 45px;
    font-weight: 400 !important;

    width: var(--main-width);
    text-align: center;
    margin-top: 96px;
    margin-bottom: 96px;
    font-weight: 600;
  }
  #header {
    display: flex;
  }
}
#hum {
  position: fixed;
  right: 12px;
  top: 12px;
  z-index: 20000000;
  border-radius: 50%;
}
#mobilemenu {
  overflow-y: hidden;
  position: fixed;
  top: 0px !important;
  z-index: 400000000000;
  background: white;
  width: 100vw;
  height: 100vh !important;
}
#mobilemenu > div {
  margin: auto;
  text-align: center;
  margin-top: 48px;
}
.voice {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-size: 17px;
  width: calc(960px / 2);
  background: white;
  padding: 16px;
  height: 204px;
  z-index: 4 !important;
}
.saturday {
  font-family: Saturday;
  font-size: 72px;
}

/* titoli con sfalsato */
.antonio {
  font-size: 44px !important;
}
.saturday-up {
  font-family: Saturday;
  margin-top: 12px;
  font-size: 38px;
}

.voice {
  margin: 12px !important;
  padding: 12px !important;
  box-sizing: border-box;
  z-index: 4 !important;
}
a:hover {
  text-decoration: none !important;
}
@media only screen and (min-width: 1366px) {
  /* .flex-row {
    width: 1280px !important;
    max-width: 1280px !important;
  }
*/
  .xl > div {
    max-width: 640px;
  }
  .xl {
    max-width: 1280px !important;
    width: 1280px !important;
  }
  .polaroid-hero {
    height: 640px !important;
    width: 640px !important;
    background: #fff;

    z-index: 120 !important;
    padding: 12px !important;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }
  .polaroid-hero > img {
    object-fit: cover;
    aspect-ratio: 1/1;
    object-fit: cover;
    width: calc(640px - 32px);
    max-height: calc(100vh - 132px) !important;

    height: auto;
  }
  .herotext-hero {
    font-size: 72px !important;
    position: absolute;
    left: 64px;
    bottom: calc(50vh - 12px);
  }
  .vivatext-hero {
    font-size: 86px !important;
    bottom: calc(50vh - 204px);
    margin-top: 12px;
    font-family: Saturday;

    color: #808d4b;
    position: absolute;
    left: 104px;
    transform: rotate(-15deg);
  }
  /*.text {
    width: 640px !important;
    max-width: 640px !important;
  }*/
  .flex-row > div:not(.polaroid) {
    max-width: 640px !important;
  }
  /*
  .polaroid {
    height: 480px !important;
    width: 480px !important;
    max-width: 480px !important;
    background: #fff;
    z-index: 3 !important;
    padding: 32px;
    -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }
  .polaroid > img {
    object-fit: cover;
    aspect-ratio: 1/1;
    object-fit: cover;
    max-width: 100%;
    height: auto;
  }
  .voice {
    width: 32vw;
  }
  .row-noflex {
    min-height: 480px;
    width: 1280px !important;
    max-width: 1280px !important;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }*/
}
svg.social-icon {
  height: 52px !important;
  margin: 32px;
}
svg.social-icon > path {
  /* fill:red; */

  fill: #333a3d;
}
svg.social-icon:hover > path {
  fill: #adc0cc;
}
.button[black] {
  border: 0;
}

.polaroid > img {
  opacity: 0;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -ms-transition: all 2s ease;
  -o-transition: all 2s ease;
}
.polaroid-hero > img {
  opacity: 0;
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -ms-transition: all 2s ease;
  -o-transition: all 2s ease;
}
.jack {
  opacity: 0;
  /* -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0); */
}
.an_fadeIn {
  opacity: 0;
  -webkit-transform: translate3d(0, -20%, 0);
  transform: translate3d(0, -20%, 0);
}
.polaroid {
  opacity: 0;
}
.polaroid-mobile {
  opacity: 0;
}

.button {
  opacity: 0;
}
div > .link:hover {
  text-decoration: underline !important;
}


/* .social-icon > :deep(path) {
  fill: red !important;
} */
/* Largest devices such as desktops (1280px and up) */
</style>
