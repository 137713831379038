import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
/* import Buefy from 'buefy'
import 'buefy/dist/buefy.css'; */
import 'mapbox-gl/dist/mapbox-gl.css';
import 'ant-design-vue/dist/antd.less';
import "tabulator-tables/dist/css/bootstrap/tabulator_bootstrap.min.css";
Vue.config.productionTip = false;
// import VuePrlx from 'vue-prlx'
// Vue.use(VuePrlx);


Vue.use(Antd);

import AOS from 'aos';
// import 'aos/dist/aos.css'; // You can also use <link> for styles
// import "locomotive-scroll"

// AOS.init({
//     once: false, // whether animation should happen only once - while scrolling down
//     mirror: false, //
//     delay: 50, //
//     disable: false,
//     easing:"ease-out"
// });

// import firebase from "./firebaseConfig";
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
// const appCheck = firebase.appCheck();
// // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// // key is the counterpart to the secret key you set in the Firebase console.

// appCheck.activate(
// "6Lcj4gMeAAAAAPjRNU4GkKUHNZ_CrpUrXGA4emTM",
//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   false);


// var storageRef = firebase.storage();



// var configRef = firebase.database().ref("/config");
// Vue.prototype.$firebase = firebase;
// Vue.prototype.$db = firebase.database();
// Vue.prototype.$places = firebase.database().ref("/places");
// Vue.prototype.$events = firebase.database().ref("/events");
// Vue.prototype.$categories = firebase.database().ref("/categories");
// Vue.prototype.$sub_categories = firebase.database().ref("/sub_categories");
// Vue.prototype.$storage = storageRef;


// Vue.prototype.$places.once("value", async function (snapshot) {
//   let places = [];

//   console.log(snapshot);
//   console.timeEnd("places");

//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var data = childSnapshot.val();

//     let imgUrls = [];

//     places.push({
//       key: key,
//       ...data,
//       imgUrls: imgUrls.length > 0 ? imgUrls : [null]
//     });

//   });
//   console.log(places);
//   store.commit("places", places);

// });

// Vue.prototype.$events.on("value", function (snapshot) {
//   let events = []

//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var data = childSnapshot.val();
//     events.push({
//       key: key,
//       ...data
//     });
//   });

//   store.commit("events", events);
// });

// Vue.prototype.$categories.on("value", function (snapshot) {
//   let categories = []

//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var data = childSnapshot.val();
//     categories.push(data);
//   });

//   store.commit("categories", categories);
// });

// Vue.prototype.$sub_categories.on("value", function (snapshot) {
//   let categories = []

//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var data = childSnapshot.val();
//     categories.push({ key, ...data });

//   });

//   store.commit("sub_categories", categories);
// });




// configRef.once('value', function (snapshot) {
//   let config = {}

//   snapshot.forEach(function (childSnapshot) {
//     var key = childSnapshot.key;
//     var data = childSnapshot.val();
//     config[key] = data;
//   });

//   Vue.prototype.$config = config

//   store.commit("login", config.startLogged);

//   Vue.config.productionTip = false

//   new Vue({
//     router,
//     store,
//     render: function (h) {
//       return h(App)
//     }
//   }).$mount('#app')
// }.bind(this));

let config = {
    startLogged:false
}

Vue.prototype.$config = config

store.commit("login", config.startLogged);

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')