
<template>
  <div style="height: 100% !important; text-align: Center">
    <div class="vmuts-background" style="height: 35vh; line-height: 58px;position:relative">
      <a href="/#/home"><img style="position:absolute;right:32px;top:32px;height:18px;" src="./close.svg" /></a>
<div
        class="antonio"
        style="
          height: 100%;
          display: flex;
          flex-direction:column;
          align-items: center;
          justify-content: center;
        "
      >
       <img
         style="height:92px"

          src="./vegan meetup ts_logo.png"
        />
       Termini e condizioni
      </div>
    </div>

    <p
      class="info"
      style="margin: 5vh 10vw 0vh 10vw; width: calc(80vw) !important"
    >
    <!-- <strong>Termini e condizioni</strong><br/> -->
I materiali (logo, testi, illustrazioni, immagini e video) presenti sul sito sono di proprietà di Vegan Meetup
Trieste. Non è permesso il loro utilizzo per finalità commerciali, se non previa espressa autorizzazione da
richiedere per iscritto. A tale scopo, oppure se sei giornalista e necessiti di immagini o altri contenuti, ti
invitiamo a contattarci scrivendo a veganmeetupts@gmail.com.
<br/><br/>
Il sito www.veganmeetup.it può proporre link ad altri siti esterni per offrire spunti e suggerimenti. Vegan
Meetup Trieste non è responsabile circa la loro disponibilità, il loro contenuto, i prodotti e i servizi offerti da
terzi.
    </p>

  </div>
</template>
<style>
.antonio {
  font-size: 44px !important;
}

.info {
  margin-top: 24px;
  line-height: 28px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  text-align: left !important;
}
</style>
<script>
export default {
  name: "Home",
  data() {
    return {};
  },
  components: {},
};
</script>

