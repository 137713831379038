import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loggedIn: false,
    places: [],
    events: [],
    categories:[],
    sub_categories:[]
  },
  mutations: {
    places(state, payload) {
      return state.places = payload
    },
    categories(state, payload) {
      return state.categories = payload
    },
    sub_categories(state, payload) {
      return state.sub_categories = payload
    },
    events(state, payload) {
      return state.events = payload
    },
    login(state, payload) {
      return state.loggedIn = payload
    }
  },
  actions: {},
  modules: {}
})
