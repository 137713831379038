<template>
  <div id="app">
    <router-view />
    <!-- <footer>
     <cookie-law theme="dark-lime"></cookie-law> 
    </footer> -->
  </div>
</template>
<script>


// import CookieLaw from "vue-cookie-law";
export default {
  components: {  },
};
</script>
<style>
 /* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");  */
#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #373737;
}
::-webkit-scrollbar {
  display: none;
}

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}
html {
  scrollbar-color: smooth!important;
}
</style>
