<template>
  <div class="main">
    <div class="filtriMobileIcon">
      <div
        class="badge"
        style="margin-right: 0px !important;background:white;"
        @click="showFiltriAvanzati"
      >
        <a-icon type="setting" style="margin-right: 12px"></a-icon>Filtri
      </div>
    </div>
    <div id="subfilters" :hidden="!showSubFilters">
      <div id="subfilters_main">
        <div id="subfilters_modal">
          <div id="modal_header">
            <div>Filtri</div>
            <div
              style="
                width: 24px;
                height: 24px;
                cursor: pointer;
                text-align: right;
              "
              @click="() => (showSubFilters = false)"
            >
              <svg
                class="x"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="24" height="24" fill="white" />
                <path
                  d="M7 17L16.8995 7.10051"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 7.00001L16.8995 16.8995"
                  stroke="#000000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div style="height: 12px"></div>
          <div id="modal_content">
            <div>
              <div id="filtriMobile" >
                <div
                  style="display: flex; flex-direction: row; flex-wrap: wrap"
                >
                  <div
                    v-for="cat of macro_categories"
                    :key="cat"
                    class="badge"
                    style="margin-bottom: 8px"
                    @click="setFilters"
                    :tipologia="cat"
                  >
                    {{ cat.toLowerCase() }}
                  </div>
                  <div
                    collaborazioni
                    class="badge"
                    style="margin-bottom: 8px"
                    @click="filtraCollaborazioni"
                  >
                    Collaborazioni attive
                  </div>
                </div>
              </div>
              <div style="height: 8px"></div>
              <div
                style="
                  max-height: calc(100% - 40px);
                  overflow-y: scroll;
                  position: relative;
                "
              >
                <!-- <div class="obscurer"></div> -->
                <div
                  v-for="(macro, cindex) of macro_categories"
                  :key="cindex"
                  style="margin-bottom: 12px"
                >
                  <div
                    style="
                      margin-top: 0px;
                      padding-top: 12px;
                      border-top: 1px solid #d9d9d9;
                      margin-bottom: 8px;
                      font-weight: 500;
                      text-transform: capitalize;
                    "
                    v-show="
                      categories.filter((q) => q.type == macro).length > 0
                    "
                  >
                    {{ macro.toLowerCase() }}
                  </div>

                  <a-checkbox-group
                    v-model="sub_filter_per_categories[macro]"
                    name="checkboxgroup"
                    :options="
                      categories
                        .filter((q) => q.type == macro)
                        .map((d) => d.name)
                    "
                    @change="changeSubCategory(macro, $event)"
                  />
                  
                </div>
                <div style="height:48px"></div>
              </div>
            </div>          
          </div>

          <div id="modal_footer">
            <div class="button filtri-buttons" @click="cancellaTutto">
              Cancella tutto
            </div>
            <div class="button filtri-buttons" @click="mostraRisultati" black>
              Mostra risultati
            </div>
          </div>
        </div>
      </div>

      <!-- <div
        v-for="category of categories"
        :key="category.key"
        style="display: flex; flex-direction: row; width: 100%"
      >
        <div>
          <a-switch
            size="small"
            @change="changeSubCategory(category, $event)"
          />
        </div>
        <span style="margin-left: 6px">{{ category.name }}</span>
      </div> -->
    </div>
    <div
      v-if="current.hasOwnProperty('name') && !viewSpallaMobile"
      id="placePopupMobile"
      @click="viewDetailMobile(current)"
      style="display: flex; padding: 12px;position:relatives"
    > <div style="z-index:96;position:absolute;right:12px;top:8px"  @click="closePlacePopupMobile($event)">
           <img
              loading="lazy"
             
              style="
                transform: rotate(0deg);
                height: 10px;

                line-height: 52px;
              "
              src="./close.svg"
            />
        </div>
      <div style="position: relative">
       
        <figure
          style="
            border-radius: 0;
            align-text: center;
            border-bottom: 1px solid #fafafa;
          "
        >
          <img
            style="height: 72px; width: 96px; border-radius: 0"
            loading="lazy"
            :src="
              current.upload.length > 0 ? current.upload[0].url : defaultImg
            "
            alt="image"
          />
        </figure>
        <div
          class="collaborazione"
          v-if="current.collaborazione_attiva"
          style="
            transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
            position: absolute;
            left: -8px;
            bottom: 4px;
          "
        >
          <div class="title">COLLABORAZIONE</div>
        </div>
      </div>
      <div style="margin-left: 12px; position: relative">
        <div style="height:48px;min-width:calc(100vw - 204px);max-width:calc(100vw - 204px)"> {{ current.name }}</div>

        <div style="position: relative">
          <div
            v-for="tipologia of current.macro_tipologia"
            :key="tipologia"
            class="type-badge"
            v-bind:[tipologia]="tipologia"
          >
            {{ tipologia }}
          </div>
        </div>
      </div>
    </div>
    <div
      id="detailMobile"
      v-show="showDetailMobile"
      style="
        z-index: 900000000000000;
        width: 100vw;
        overflow-y:scroll;
        overflow-x:hidden;
        top: 0;
        left: 0;
        background: white;
      "
    >
      <div style="position: relative;">
        <div style="position: absolute; right: 24px">
          <div
            @click="closeDetailMobile"
            class="listButton_mobile"
            style="position: relative; top: 12px; left: 12px; cursor: pointer"
          >
            <img
              loading="lazy"
              style="
                transform: rotate(0deg);
                height: 12px;
                margin: 12px 12px;
                line-height: 52px;
              "
              src="./close.svg"
            />
          </div>
        </div>
        <div
          v-for="tipologia of current.macro_tipologia"
          :key="tipologia"
          style="position: absolute; bottom: 12px; left: 12px"
          class="type-badge"
          v-bind:[tipologia]="tipologia"
        >
          {{ tipologia }}
        </div>

        <figure
          style="
            height: 272px;
            width: 100%;
            border-radius: 0;
            align-text: center;
            border-bottom: 1px solid #fafafa;
          "
        >
          <img
            loading="lazy"
            style="height: 272px; width: 100%; border-radius: 0"
            :src="
              current.upload.length > 0 ? current.upload[0].url : defaultImg
            "
            alt="image"
          />
        </figure>
      </div>
      <div style="padding-bottom:48px">
      <div style="padding: 0px 24px">
        <div class="place_name_left">
          {{ current.name }}
        </div>
        <div class="line" @click="zoomToPlaceMobile(current)">
          <img
            src="./noun-location-4696693.svg"
            style="width: 13px; margin-right: 8px"
          /><span class="line"
            >{{ current.indirizzo }} - {{ current.citta }}</span
          >
        </div>
        <div class="line">
          <img
            src="./noun-call-4696688.svg"
            style="width: 13px; margin-right: 8px"
          />{{ current.telefono }}
        </div>
        <div class="line">
          <img
            src="./noun-alarm-4696692.svg"
            style="width: 13px; margin-right: 8px"
          />{{ current.orari }}
        </div>
        <div
          style="
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="line">
            <img
              v-if="current.www"
              src="./noun-world-1287478.svg"
              style="width: 14px; margin-right: 8px"
            /><a
              class="locale-link"
              target="_blank"
              @click="stop"
              :href="formatWWWLink(current.www)"
              >{{ formatWWW(current.www) }}</a
            >
          </div>
          <div style="display: flex; flex-grow: 1"></div>
          <div style="display: flex">
            <div v-if="current.facebook">
              <a
                target="_blank"
                @click="stop"
                :href="formatWWWLink(current.facebook)"
              >
                <img class="aimg" src="./imgs/social/icon-facebook.svg"
              /></a>
            </div>
            <div style="margin-left: 12px" v-if="current.instagram">
              <a
                target="_blank"
                @click="stop"
                :href="formatWWWLink(current.instagram)"
              >
                <img class="aimg" src="./imgs/social/icon-instagram.svg"
              /></a>
            </div>
            <div style="margin-left: 12px" v-if="current.twitter">
              <a
                class="social"
                target="_blank"
                @click="stop"
                :href="formatWWWLink(current.twitter)"
              >
                <img class="aimg" src="./social-twitter.svg"
              /></a>
            </div>
          </div>
        </div>

        <div style="height: 12px"></div>
        <div class="collaborazione2" v-if="current.collaborazione_attiva">
          <div style="font-size: 10px; font-weight: 600; color: #658367">
            COLLABORAZIONE IN CORSO
          </div>
          <div style="height: 4px"></div>
          <div class="description">{{ current.descrizione_collaborazione }}</div>
        </div>
        <div style="margin: 24px 0px;" class="description">{{ current.descrizione }}</div>
        <div style="height: 24px"></div>
      </div></div>
    </div>
    <div id="placePopupDesktop" v-show="viewSpalla">
      <div style="position: relative">
        <div style="position: absolute">
          <div
            @click="closeSpalla"
            class="listButton"
            style="
              z-index: 200000;
              background: white;
              position: absolute;

              top: 12px;
              left: 572px;
              cursor: pointer;
            "
          >
            <img
              style="transform: rotate(0deg); height: 12px; margin: 12px 12px"
              src="./close.svg"
            />
          </div>
        </div>
        <div style="position: absolute; right: 12px; bottom: 12px"></div>
        <div
          style="
            position: absolute;
            bottom: 12px;
            z-index: 1800000;
            left: 12px;
            display: flex;
          "
        >
          <div
            v-for="tipologia of current.macro_tipologia"
            :key="tipologia"
            class="type-badge"
            v-bind:[tipologia]="tipologia"
          >
            {{ tipologia }}
          </div>
        </div>
        <!--QUI-->

        <a-carousel
          :autoplay="false"
          style="height: 272px; width: 100%"
          v-if="current.upload.length > 0"
        >
          <img
            loading="lazy"
            v-for="(img, index) of [current.upload[0]]"
            :key="index"
            style="
              height: 272px;
              width: 100%;
              align-text: center;
              border-bottom: 1px solid #fafafa;
            "
            :src="img.url || defaultImg"
            alt="Placeholder image"
          />
        </a-carousel>
        <img
          v-else
          :key="index"
          style="
            height: 272px;
            width: 100%;
            align-text: center;
            border-bottom: 1px solid #fafafa;
          "
          :src="defaultImg"
          alt="Placeholder image"
        />
      </div>
      <div style="padding: 0px 24px">
        <div class="place_name_left">
          {{ current.name }}
        </div>
        <div
          style="
            margin-bottom: 12px;
            color: #a4a5a5;
            font-family: Poppins;
            font-size: 15px;
          "
        >
          {{ flatten([current.sotto_tipologia]).join(" | ") }}
        </div>
        <div class="line detail">
          <img
            src="./noun-location-4696693.svg"
            style="width: 13px; margin-right: 8px"
          /><span class="line"
            >{{ current.indirizzo }} - {{ current.citta }}</span
          >
        </div>
        <div class="line detail">
          <img
            src="./noun-call-4696688.svg"
            style="width: 13px; margin-right: 8px"
          />{{ current.telefono }}
        </div>
        <div class="line detail">
          <img
            src="./noun-alarm-4696692.svg"
            style="width: 13px; margin-right: 8px"
          />{{ current.orari }}
        </div>
        <div
          style="
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
          "
        >
          <div class="line detail">
            <img
              v-if="current.www"
              src="./noun-world-1287478.svg"
              style="width: 14px; margin-right: 8px"
            /><a
              class="locale-link"
              v-if="current.www"
              target="_blank"
              @click="stop"
              :href="formatWWWLink(current.www)"
              >{{ formatWWW(current.www) }}</a
            >
          </div>
          <div style="display: flex; flex-grow: 1"></div>
          <div style="display: flex">
            <div v-if="current.facebook">
              <a
                target="_blank"
                @click="stop"
                :href="formatWWWLink(current.facebook)"
              >
                <img class="aimg" src="./imgs/social/icon-facebook.svg"
              /></a>
            </div>
            <div style="margin-left: 12px" v-if="current.instagram">
              <a
                target="_blank"
                @click="stop"
                :href="formatWWWLink(current.instagram)"
              >
                <img class="aimg" src="./imgs/social/icon-instagram.svg"
              /></a>
            </div>
            <div style="margin-left: 12px" v-if="current.twitter">
              <a
                target="_blank"
                @click="stop"
                :href="formatWWWLink(current.twitter)"
              >
                <img class="aimg" src="./social-twitter.svg"
              /></a>
            </div>
          </div>
        </div>
        <div style="height: 12px"></div>
        <div class="collaborazione2" v-if="current.collaborazione_attiva">
          <div style="font-size: 10px; font-weight: 600; color: #658367">
            COLLABORAZIONE IN CORSO
          </div>
          <div style="height: 4px"></div>
          <div class="description">{{ current.descrizione_collaborazione }}</div>
        </div>
        <div style="margin: 24px 0px" class="description">{{ current.descrizione }}</div>
        <div style="height: 24px"></div>
        <img
          class="image_masonry"
          v-for="(image, i) in current.upload"
          :src="image.url"
          :key="i"
          @click="index = i"
        />
        <vue-gallery-slideshow
          :images="current.upload.map((q) => q.url)"
          :index="index"
          @close="index = null"
        ></vue-gallery-slideshow>
        <div style="height: 72px"></div>
      </div>
    </div>

    <div
      id="header"
      class="map_stuff"
      style="
        height: 52px;
        width: 100vw;
        line-height: 52px;
        font-size: 24px;
        padding: 0px 12px;
        background: black;
        color: white;
      "
    >
      <div
        style="height: 52px; width: 52px; cursor: pointer"
        @click="$router.push('/home')"
      >
        <img
          class="maplogo"
          src="./vegan meetup ts_logo.png"
          style="cursor: pointer"
        />
        <div id="title-mobile">VEGAN MEETUP TRIESTE</div>
        <div style="display: flex; flex-grow: 1"></div>
        <div
          class="under"
          @click="$router.push({ path: '/home' })"
          style="
            color: white;
            position: absolute;
            right: 12px;
            top: 0;
            font-size: 12px;
          "
        >
          VAI AL SITO<span style="margin-left: 4px">
            <a-icon type="arrow-right"
          /></span>
        </div>
      </div>
    </div>
    <div
      id="placePopup"
      v-show="popup"
      style="
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
        position: absolute;
        width: 192px;
        height: 204px;
        z-index: 100;
        background: white;
      "
    >
      <div style="position: relative; padding: 6px">
        <figure @click="openSpalla" style="height: 104px; width: 200px">
          <img
            style="
              height: 104px;
              width: 192px;
              border-radius: 0;
              cursor: pointer;
            "
            :src="defaultImg"
            alt="Placeholder image"
          />
        </figure>

        <div
          v-for="tipologia of current.macro_tipologia"
          :key="tipologia"
          v-bind:[tipologia]="tipologia"
          class="type-badge"
          style="
            position: absolute;
            right: 0px;
            top: 12px;
            color: white;
            line-height: 12px;
            height: 20px;
            padding: 3px 4px;
          "
        >
          <div>{{ tipologia }}</div>
        </div>

        <div class="popup_name">
          {{ current.name }}
        </div>
      </div>
    </div>
    <div id="spallaMobile" v-show="viewSpallaMobile">
      <div style="width: 100%; height: 48px; margin-top: 12px">
        <div id="mobile-mappa" @click="hide_elenco_mobile">Mappa</div>
      </div>
      <div
        style="
          position: absolute;
          top: 64px;
          overflow-y: scroll;
       
          max-height: calc(100vh - 172px);
        "
      >
        <div
          @click="viewDetailMobile(place)"
          v-for="place of filtered_places"
          :key="place.key"
          style="cursor: pointer"
        >
          <div
            class="card"
            style="
              text-align: left;
              border-bottom: 1px solid #eaeaea;
              padding: 0px 12px 12px 12px;
              width: 100%;
            "
          >
            <div style="position: relative">
              <div
                class="collaborazione"
                v-if="place.collaborazione_attiva"
                style="
                  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
                  position: absolute;
                  left: -8px;
                  bottom: 4px !important;
                "
              >
                <div class="title">COLLABORAZIONE</div>
              </div>
              <img
                style="height: 72px; width: 96px"
                :src="
                  place.upload.length > 0 ? place.upload[0].url : defaultImg
                "
                alt="Placeholder image"
              />
            </div>

            <div style="padding: 0px 12px;height:72px;display:flex;flex-direction:column;justify-content:space-between">
              <div>
                <div class="place_name_mobile">
                  <b style="color: #373737; cursor: pointer">{{
                    place.name
                  }}</b>
                </div>
              </div>

              <div style="display: flex">
                <div
                  v-for="tipologia of place.macro_tipologia"
                  :key="tipologia"
                  class="type-badge"
                  v-bind:[tipologia]="tipologia"
                >
                  {{ tipologia }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex">
      <div id="leftPanelDesktop" :hidden="!isOpen || viewSpalla">
        <div
          style="
            padding: 0px 12px 0px 24px;
            height: calc(100vh - 104px);
            overflow-y: scroll;
          "
        >
          <div style="border-bottom: 1px solid #eaeaea; padding: 12px 0px">
            Risultati: {{ filtered_places.length }}
          </div>
          <div
            v-for="place of filtered_places"
            :key="place.key"
            style="margin-top: 12px; cursor: pointer"
          >
            <div
              class="card"
              style="
                text-align: left;
                border-bottom: 1px solid #eaeaea;
                padding: 0px 0px 4px 0px;
                width: 100%;
              "
            >
              <div style="position: relative">
                <div
                  class="collaborazione"
                  v-if="place.collaborazione_attiva"
                  style="
                    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
                    position: absolute;
                    left: -8px;
                    top: 8px !important;
                    z-index: 120000000;
                  "
                >
                  <div class="title">COLLABORAZIONE</div>
                </div>
                <!-- <a-carousel
                  :ref="'car_' + place.key"
                  style="height: 176px; width: 252px"
                >
                  <img
                    v-for="(img, index) of place.upload"
                    :key="index"
                    style="height: 192px; width: 252px"
                    :src="img.url || defaultImg"
                    alt="Placeholder image"
                  />
                </a-carousel> -->
                <!-- CAROUSEL HERE -->
                <img
                  @click="zoomToPlace(place)"
                  style="height: 192px; width: 252px"
                  :src="
                    place.upload.length > 0 ? place.upload[0].url : defaultImg
                  "
                  alt="Placeholder image"
                />
              </div>

              <div
                style="padding: 6px 12px; width: calc(100% - 0px)"
                @click="zoomToPlace(place)"
              >
                <div style="display: flex">
                  <div
                    v-for="tipologia of place.macro_tipologia"
                    :key="tipologia"
                    class="type-badge"
                    v-bind:[tipologia]="tipologia"
                  >
                    {{ tipologia }}
                  </div>
                </div>
                <div
                  style="
                    margin-top: 12px;
                    color: #a4a5a5 !important;
                    font-family: Poppins;
                    font-size: 13px;
                  "
                >
                  {{ flatten([place.sotto_tipologia]).join(" | ") }}
                </div>
                <div>
                  <div class="place_name">
                    <b style="color: #373737; cursor: pointer">{{
                      place.name
                    }}</b>
                  </div>
                </div>
                <div class="line">
                  <img
                    src="./noun-location-4696693.svg"
                    style="width: 13px; margin-right: 8px"
                  /><span class="line"
                    >{{ place.indirizzo }} - {{ place.citta }}</span
                  >
                </div>
                <div class="line">
                  <img
                    src="./noun-call-4696688.svg"
                    style="width: 13px; margin-right: 8px"
                  />{{ place.telefono }}
                </div>
                <div class="line">
                  <img
                    src="./noun-alarm-4696692.svg"
                    style="width: 13px; margin-right: 8px"
                  />{{ place.orari }}
                </div>
                <div
                  style="
                    margin-top: 12px;
                    display: flex;
                    justify-content: space-between;
                  "
                >
                  <div class="line">
                    <img
                      v-if="place.www"
                      src="./noun-world-1287478.svg"
                      style="width: 14px; margin-right: 8px"
                    /><a
                      class="locale-link"
                      v-if="place.www"
                      target="_blank"
                      @click="stop"
                      :href="formatWWWLink(place.www)"
                      >{{ formatWWW(place.www) }}</a
                    >
                  </div>
                  <div style="display: flex; flex-grow: 1"></div>
                  <div style="display: flex">
                    <div v-if="place.facebook">
                      <a
                        target="_blank"
                        @click="stop"
                        :href="formatWWWLink(place.facebook)"
                      >
                        <img class="aimg" src="./imgs/social/icon-facebook.svg"
                      /></a>
                    </div>
                    <div style="margin-left: 12px" v-if="place.instagram">
                      <a
                        target="_blank"
                        @click="stop"
                        :href="formatWWWLink(place.instagram)"
                      >
                        <img
                          class="aimg"
                          src="./imgs/social/icon-instagram.svg"
                      /></a>
                    </div>
                    <div style="margin-left: 12px" v-if="place.twitter">
                      <a
                        target="_blank"
                        @click="stop"
                        :href="formatWWWLink(place.twitter)"
                      >
                        <img class="aimg" src="./social-twitter.svg"
                      /></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          id="subHeader"
          style="display: flex; justify-content: space-between"
        >
          <div style="display: flex">
            <div
              v-for="cat of macro_categories"
              :key="cat"
              class="badge"
              @click="setFilters"
              :tipologia="cat"
            >
              {{ cat.toLowerCase() }}
            </div>
            <div collaborazioni class="badge" @click="filtraCollaborazioni">
              Collaborazioni attive
            </div>
          </div>
          <div
            class="badge_av"
            style="margin-right: 0px !important"
            @click="showFiltriAvanzati"
          >
            <a-icon type="setting" style="margin-right: 12px"></a-icon>Filtri
            avanzati
          </div>
        </div>
        <div id="map"></div>
        <div
          v-show="!viewSpallaMobile && !showSubFilters"
          id="mobile-elenco"
          @click="show_elenco_mobile"
          :active="current.hasOwnProperty('name')"
        >
          Elenco
        </div>
        <div class="listButton" id="listButtonClosed" v-if="!isOpen">
          <div style="margin-left: 16px; cursor: pointer" @click="open">
            Mostra lista
          </div>
          <img
            @click="open"
            style="
              cursor: pointer;
              transform: rotate(180deg);
              height: 12px;
              margin: 12px 12px;
              line-height: 52px;
            "
            src="./back.svg"
          />
        </div>
        <div
          class="listButton"
          id="listButtonOpen"
          v-if="isOpen && !viewSpalla"
          @click="close"
          style="cursor: pointer"
        >
          <img
            src="./back.svg"
            style="height: 12px; margin: 12px 16px 12px 16px; line-height: 52px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
const axios = require("axios");
import VueGallerySlideshow from "vue-gallery-slideshow";

const _ = require("lodash");
import mapboxgl from "mapbox-gl"; // or "const mapboxgl = require('mapbox-gl');"
const pasticceria = require("./marker-PASTICCERIA.svg");
const ristorante = require("./marker-RISTORANTE.svg");
const bar = require("./marker-BAR.svg");
const servizi = require("./marker-SERVIZI.svg");
const shop = require("./marker-SHOP.svg");
const other = require("./marker-OTHER.svg");
const defaultImg = require("./defaultImg.js");

export default {
  name: "Map",

  computed: {
    filtered_places() {
      return this.__places.filter((q) => {
        let valid = false;

        if (q.macro_tipologia === undefined) return false;

        if (this.sub_filters.length == 0) {
          let all_tipologie = _.flatten([q.macro_tipologia])
            .map((d) => d.toUpperCase())
            .join(",");
          this.filters.map((f) => {
            if (all_tipologie.includes(f)) valid = true;
          });
        } else {
          let all_tipologie = _.flatten([q.sotto_tipologia])
            .map((d) => d.toUpperCase())
            .join(",");

          this.sub_filters.map((f) => {
            if (all_tipologie.includes(f.toUpperCase())) valid = true;
          });
        }

        if (this.collaborazioniFilter && !q.collaborazione_attiva)
          valid = false;

        return valid;
      });
    },
  },
  methods: {
    closePlacePopupMobile(e) {

              this.current= { imgUrls: [null], upload: [{ url: null }] },

        e.preventDefault();
        e.stopPropagation()
    },
    mostraRisultati() {
      this.showSubFilters = false;
    },
    cancellaTutto() {
      this.sub_filters = [];
      this.macro_categories.map((macro) => {
        this.sub_filter_per_categories[macro] = [];
      });
      this.changeSubCategory();
    },
    filtraRisultati() {},
    showFiltriAvanzati() {
      this.showSubFilters = true;
    },
    filtraCollaborazioni(e) {
      let filter = false;

      if (!e.target.hasAttribute("selected")) {
        e.target.setAttribute("selected", true);
        filter = true;
        //this.map.setLayoutProperty(layer.id, "visibility", "visible");
      } else {
        e.target.removeAttribute("selected");
        filter = false;
        //this.map.setLayoutProperty(layer.id, "visibility", "none");
      }

      this.collaborazioniFilter = filter;

      let newGeoJson = {
        features: this._places
          .filter((q) => {
            let valid = false;

            if (q.macro_tipologia === undefined) return false;
            let all_tipologie = _.flatten([q.sotto_tipologia])
              .map((d) => d.toUpperCase())
              .join(",");

            let sub_filters =
              this.sub_filters.length == 0
                ? this.categories.map((d) => d.value)
                : this.sub_filters;
            sub_filters.map((f) => {
              if (all_tipologie.includes(f.toUpperCase())) valid = true;
            });
            if (this.collaborazioniFilter && !q.collaborazione_attiva)
              valid = false;
            return valid;
          })
          .map((place) => {
            try {
              return {
                upload: place.upload,
                ...place.location,
                properties: {
                  upload: place.upload,
                  ...place,
                  lat: +place.lat,
                  lng: +place.lng,
                  master_tipologia: place.macro_tipologia[0],
                  _tipologia: _.flatten([place.macro_tipologia]).map((d) =>
                    d.toLowerCase()
                  ),
                },
              };
            } catch (e) {}
          }),
      };

      this.map.getSource("clusters").setData(newGeoJson);
    },
    flatten: _.flatten,
    changeSubCategory(e, event) {
      let sub_filters = [];
      Object.keys(this.sub_filter_per_categories).map((l) => {
        sub_filters.push(this.sub_filter_per_categories[l]);
      });
      this.sub_filters = _.flatten(sub_filters);

      let newGeoJson = {
        type: "FeatureCollection",
        features: this._places
          .filter((q) => {
            let valid = false;

            if (q.macro_tipologia === undefined) return false;
            let all_tipologie = _.flatten([q.sotto_tipologia])
              .map((d) => d.toUpperCase())
              .join(",");

            let sub_filters =
              this.sub_filters.length == 0
                ? this.categories.map((d) => d.value)
                : this.sub_filters;
            sub_filters.map((f) => {
              if (all_tipologie.includes(f.toUpperCase())) valid = true;
            });
            if (this.collaborazioniFilter && !q.collaborazione_attiva)
              valid = false;
            return valid;
          })
          .map((place) => {
            try {
              return {
                ...place.location,
                properties: {
                  ...place,
                  lat: +place.lat,
                  lng: +place.lng,
                  master_tipologia: place.macro_tipologia[0],
                  _tipologia: _.flatten([place.macro_tipologia]).map((d) =>
                    d.toLowerCase()
                  ),
                },
              };
            } catch (e) {}
          }),
      };

      this.map.getSource("clusters").setData(newGeoJson);

      //SOLVING BUG WITH FILTERS
    },
    formatWWW(e) {
      if (e)
        return e
          .replace("https://", "")
          .replace("http://", "")
          .replace(/\/$/, "");
      return "";
    },
    formatWWWLink(e) {
      if (e)
        return (
          "https://" +
          e.replace("https://", "").replace("http://", "").replace(/\/$/, "")
        );
      return "";
    },
    viewDetailMobile(e) {
      this.current = { imgUrls: [], ...e };

      this.showDetailMobile = true;
    },
    closeDetailMobile() {
      this.current = { ...this.current, imgUrls: [] };
      this.showDetailMobile = false;
    },
    show_elenco_mobile(e) {
      this.viewSpallaMobile = true;
    },
    hide_elenco_mobile(e) {
      this.viewSpallaMobile = false;
    },
    stop(e) {
      e.stopPropagation();
    },
    setFilters(e) {
      this.sub_filters = [];
      if (!e.target.hasAttribute("selected")) {
        e.target.setAttribute("selected", true);
        //this.map.setLayoutProperty(layer.id, "visibility", "visible");
      } else {
        e.target.removeAttribute("selected");
        //this.map.setLayoutProperty(layer.id, "visibility", "none");
      }

      let filters = [];
      try {
        filters = Array.from(this.$el.querySelectorAll(".badge[selected]")).map(
          (q) => q.getAttribute("tipologia")
        );
      } catch (e) {}

      filters = filters.filter((q) => q != null && q && q != "null");
      if (filters.length == 0) filters = [...this.macro_categories];

      this.filters = [...filters];

      let newGeoJson = {
        type: "FeatureCollection",
        features: this._places
          .filter((q) => {
            let valid = false;

            if (q.macro_tipologia === undefined) return false;
            let all_tipologie = _.flatten([q.macro_tipologia])
              .map((d) => d.toUpperCase())
              .join(",");

            this.filters.map((f) => {
              if (all_tipologie.includes(f)) valid = true;
            });

            this.sub_filters = []; //this.categories.map(d=>d.value);

            if (this.collaborazioniFilter && !q.collaborazione_attiva)
              valid = false;
            return valid;
          })
          .map((place) => {
            try {
              return {
                upload: place.upload,
                ...place.location,

                properties: {
                  ...place,
                  lat: +place.lat,
                  lng: +place.lng,
                  master_tipologia: place.macro_tipologia[0].toUpperCase(),
                  _tipologia: _.flatten([place.macro_tipologia]).map((d) =>
                    d.toLowerCase()
                  ),
                },
              };
            } catch (e) {}
          }),
      };

      this.map.getSource("clusters").setData(newGeoJson);
    },
    openSpalla() {
      this.viewSpalla = true;
    },
    closeSpalla() {
      console.log("spalla");
      //this.isOpen = true;
      this.viewSpalla = false;
      this.current = { ...this.current, imgUrls: [] };
    },
    open() {
      console.log("opening");
      this.isOpen = true;
    },
    close() {
      console.log("close");
      this.isOpen = false;
    },
    async makeMap() {
      this.__places = (await axios.get("/vmuts/api/places")).data
        .map((f) => {
          return {
            ...f,
            location: {
              geometry: {
                type: "Point",
                coordinates: [+f.lng, +f.lat],
              },
            },
          };
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      this.__sub_categories = (
        await axios.get("/vmuts/api/sub_categories")
      ).data;

      this.macro_categories = (
        await axios.get("/vmuts/api/macro_categories")
      ).data.filter((d) => d != null);
      this.filters = this.macro_categories;

      this.categories = this.__sub_categories
        .filter((q) => this.filters.includes(q.type))
        .map((d) => {
          return {
            type: d.type,
            label: d.name,
            value: d.name,
            name: d.name,
          };
        });

      this.sub_filter_per_categories = {};
      this.__sub_categories.map((d) => {
        this.sub_filter_per_categories[d] = [];
      });
      this.filters = this.macro_categories;
      this.sub_filters = []; //this.categories.map((d) => d.value);

      this.places = this.__places.filter((q) => {
        let valid = false;
        if (q.macro_tipologia === undefined) return false;

        let all_tipologie = _.flatten([q.macro_tipologia])
          .map((d) => d.toUpperCase())
          .join(",");

        this.filters.map((f) => {
          if (all_tipologie.includes(f)) valid = true;
        });

        if (!valid) return false;
        valid = false;
        let all_sub_tipologie = [];
        try {
          all_sub_tipologie = _.flatten([q.sotto_tipologia])
            .map((d) => d.toUpperCase())
            .join(",");
        } catch (e) {}

        let sub_filters =
          this.sub_filters.length == 0
            ? this.categories.map((d) => d.value)
            : this.sub_filters;

        sub_filters.map((f) => {
          try {
            if (all_sub_tipologie.includes(f.toUpperCase())) valid = true;
          } catch (e) {}
        });
        if (this.collaborazioniFilter && !q.collaborazione_attiva)
          valid = false;
        return valid;
      });

      mapboxgl.accessToken =
        "pk.eyJ1IjoicGJwZXJzODciLCJhIjoiY2txM2lqaXRsMGp3djJvbzBocDNheWJ3YiJ9.KMNojaw2OGjrKJELrmzFCw";

      let _places = [];

      for await (const place of this.places) {
        let imgUrls = [];

        _places.push(place);
      }

      this._places = _places;

      const map = new mapboxgl.Map({
        container: this.$el.querySelector("#map"), // container ID

        style: "mapbox://styles/pbpers87/cl12ehdif000914q8d6qb2bm4",
        // maxBounds:[
        //   [13.42, 44.92],
        //   [13.62, 45.12]
        // ],
        center: [13.52, 45.02], // starting position [lng, lat]
        zoom: 8, // starting zoom
      });
      this.map = map;
      this.map.setCenter([13.7768, 45.6495]);

      this.map.on(
        "click",
        "places",
        function (e) {
          console.log("clicking");
          this.popup = false;
          var coordinates = e.features[0].geometry.coordinates.slice();

          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }

          this.current = e.features[0];
        }.bind(this)
      );

      // Change the cursor to a pointer when the mouse is over the places layer.

      // let colors = {
      //   Pasticceria: "#D1A89B",
      //   Ristorante: "#ADC0CC",
      //   Bar: "#FCFCFC",
      //   Altro:"#AA0000",
      //   Servizi:"#00AA00",
      //   Shop:"#0000AA"
      // };

      this.map.on(
        "load",
        async function () {
          await new Promise((res, rej) => {
            let _pasticceria = new Image(48, 48);
            _pasticceria.onload = () => this.map.addImage("FOOD", _pasticceria);
            _pasticceria.src = pasticceria;
            res();
          });
          await new Promise((res, rej) => {
            let _shop = new Image(48, 48);
            _shop.onload = () => this.map.addImage("SHOP", _shop);
            _shop.src = shop;
            res();
          });
          await new Promise((res, rej) => {
            let _servizi = new Image(48, 48);
            _servizi.onload = () => this.map.addImage("SERVIZI", _servizi);
            _servizi.src = servizi;
            res();
          });
          // await new Promise((res, rej) => {
          //   let _ristorante = new Image(48, 48);
          //   _ristorante.onload = () =>
          //     this.map.addImage("Ristorante", _ristorante);
          //   _ristorante.src = ristorante;
          //   res();
          // });
          // await new Promise((res, rej) => {
          //   let _bar = new Image(48, 48);
          //   _bar.onload = () => this.map.addImage("Bar", _bar);
          //   _bar.src = bar;
          //   res();
          // });
          await new Promise((res, rej) => {
            let _other = new Image(48, 48);
            _other.onload = () => this.map.addImage("ALTRO", _other);
            _other.src = other;
            res();
          });

          let grouped = _.groupBy(this._places, "macro_tipologia");

          let geoJson = {
            type: "FeatureCollection",
            features: this._places.map((place, i) => {
              try {
                return {
                  upload: place.upload,
                  ...place.location,
                  properties: {
                    upload: place.upload,
                    ...place,
                    lat: +place.lat,
                    lng: +place.lng,
                    master_tipologia: place.macro_tipologia[0].toUpperCase(),
                    _tipologia: place.macro_tipologia.map((d) =>
                      d.toLowerCase()
                    ),
                  },
                };
              } catch (e) {
                console.log(e);
              }
            }),
          };

          this.map.addSource("clusters", {
            type: "geojson",
            generateId: true,
            // Point to GeoJSON data. This example visualizes all M1.0+ earthquakes
            // from 12/22/15 to 1/21/16 as logged by USGS' Earthquake hazards program.
            data: geoJson,
            cluster: true,
            clusterMaxZoom: 18, // Max zoom to cluster points on
            clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
          });

          this.map.addLayer({
            id: "clusters-layer",
            type: "circle",
            source: "clusters",
            filter: ["has", "point_count"],
            paint: {
              "circle-color": "grey",
              "circle-opacity": 0.33,
              "circle-stroke-width": 2,
              "circle-stroke-color": "grey",

              "circle-radius": [
                "step",
                ["get", "point_count"],
                40,
                50,
                70,
                80,
                100,
              ],
            },
          });

          this.map.on("click", "clusters-layer", (e) => {
            const features = this.map.queryRenderedFeatures(e.point, {
              layers: ["clusters-layer"],
            });
            const clusterId = features[0].properties.cluster_id;

            this.map
              .getSource("clusters")
              .getClusterExpansionZoom(clusterId, (err, zoom) => {
                if (err) return;

                this.map.easeTo({
                  center: features[0].geometry.coordinates,
                  zoom: zoom,
                });
              });
          });

          this.map.addLayer({
            id: "clusters" + "-count",
            type: "symbol",
            source: "clusters",
            filter: ["has", "point_count"],
            layout: {
              "text-field": "{point_count_abbreviated}",
              "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
              "text-size": 12,
            },
          });

          Object.keys(grouped).map((type) => {
            this.map.addLayer({
              id: type.split(",")[0].toUpperCase() + "-unclustered-point",
              type: "symbol",
              source: "clusters",
              filter: [
                "all",
                ["==", "master_tipologia", type.split(",")[0].toUpperCase()],
              ],
              layout: {
                visibility: "visible",
                "icon-allow-overlap": true,
                "icon-image": type.split(",")[0], // reference the image
                "icon-size": 1,
              },
            });

            this.map.on(
              "mouseenter",
              type.split(",")[0].toUpperCase() + "-unclustered-point",
              () => {
                this.map.getCanvas().style.cursor = "pointer";
              }
            );

            // Change it back to a pointer when it leaves.
            this.map.on(
              "mouseleave",
              type.split(",")[0].toUpperCase() + "-unclustered-point",
              () => {
                this.map.getCanvas().style.cursor = "";
              }
            );

            this.map.on(
              "click",
              type.split(",")[0].toUpperCase() + "-unclustered-point",
              async (e) => {
                console.log("click unclustered");
                // Copy coordinates array.

                const coordinates = e.features[0].geometry.coordinates.slice();

                const item = e.features[0].properties;
                let imgUrls = [];
                let upload = [];
                try {
                  upload = JSON.parse(item.upload);
                } catch (e) {}

                let newItem = { ...item, imgUrls, upload };
                try {
                  newItem.macro_tipologia = JSON.parse(newItem.macro_tipologia);
                } catch (e) {}
                try {
                  newItem.sotto_tipologia = JSON.parse(newItem.sotto_tipologia);
                } catch (e) {}
                this.current = newItem;
                Object.keys(grouped).map((type) => {
                  this.map.setLayoutProperty(
                    type.split(",")[0].toUpperCase() + "-unclustered-point",
                    "icon-size",
                    [
                      "match",
                      ["id"], // get the feature id (make sure your data has an id set or use generateIds for GeoJSON sources
                      e.features[0].id,
                      1, //image when id is the clicked feature id
                      1, // default
                    ]
                  );
                });

                this.map.setLayoutProperty(
                  type.split(",")[0].toUpperCase() + "-unclustered-point",
                  "icon-size",
                  [
                    "match",
                    ["id"], // get the feature id (make sure your data has an id set or use generateIds for GeoJSON sources
                    e.features[0].id,
                    1, //image when id is the clicked feature id
                    1, // default
                  ]
                );

                this.openSpalla();
                //this.viewDetailMobile();
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
              }
            );
          });
          if (this.$route.query.collaborazioni) {
            // this.$el
            //   .querySelector(".badge[collaborazioni]")
            //   .setAttribute("selected", true);

            this.filtraCollaborazioni({
              target: this.$el.querySelector(".badge[collaborazioni]"),
            });
          }
        }.bind(this)
      );
    },
    applyFilters() {},
    // close() {
    //   this.current = null;
    //   this.popup = false;
    // },

    zoomToPlace(place) {
      console.log("AAAAA");
      //this.isOpen = false;
      this.current = place;

      this.viewSpalla = true;
      this.popup = false;
      let e = { point: this.map.project([place.lng, place.lat]) };

      console.log(e);

      const bbox = [
        [e.point.x - 35, e.point.y - 35],
        [e.point.x + 35, e.point.y + 35],
      ];
      console.log(bbox, place.macro_tipologia[0].toUpperCase());
      const features = this.map.queryRenderedFeatures(bbox, {
        // layers: [place.macro_tipologia[0].toUpperCase() + "-unclustered-point"],
      });
      // var features = this.map.queryRenderedFeatures([place.lat,place.lng])
      console.log([place.lng, place.lat], features);
      let grouped = _.groupBy(this._places, "macro_tipologia");
      // Object.keys(grouped).map((type) => {
      //   this.map.setLayoutProperty(
      //     type.split(",")[0].toUpperCase() + "-unclustered-point",
      //     "icon-size",
      //     [
      //       "match",
      //       ["id"], // get the feature id (make sure your data has an id set or use generateIds for GeoJSON sources
      //       e.features[0].id,
      //       1, //image when id is the clicked feature id
      //       1, // default
      //     ]
      //   );
      // });
      // console.log(place)
      // this.map.setLayoutProperty(
      //   place.macro_tipologia[0].toUpperCase() + "-unclustered-point",
      //   "icon-size",
      //   [
      //     "match",
      //     ["name"], // get the feature id (make sure your data has an id set or use generateIds for GeoJSON sources
      //     place.name,
      //     1.2, //image when id is the clicked feature id
      //     1, // default
      //   ]
      // );

      this.map.flyTo({
        // These options control the ending camera position: centered at
        // the target, at zoom level 9, and north up.
        center: place.location.geometry.coordinates,
        zoom: 17,
        speed: 5,
        // These options control the flight curve, making it move
        // slowly and zoom out almost completely before starting
        // to pan.
        /*         speed: 0.2, 
        curve: 1, */

        // This can be any easing function: it takes a number between
        // 0 and 1 and returns another number between 0 and 1.
        easing: function (t) {
          return t;
        },

        // this animation is considered essential with respect to prefers-reduced-motion
        essential: true,
      });
    },
    zoomToPlaceMobile(place) {
      //place.location=JSON.parse(place.location)
      //this.isOpen = false;
      this.current = place;
      this.viewSpalla = false;

      this.showDetailMobile = false;
      this.viewSpallaMobile = false;
      this.popup = false;
      this.map.flyTo({
        // These options control the ending camera position: centered at
        // the target, at zoom level 9, and north up.
        center: place.location.geometry.coordinates,
        zoom: 17,
        speed: 5,
        // These options control the flight curve, making it move
        // slowly and zoom out almost completely before starting
        // to pan.
        /*         speed: 0.2, 
        curve: 1, */

        // This can be any easing function: it takes a number between
        // 0 and 1 and returns another number between 0 and 1.
        easing: function (t) {
          return t;
        },

        // this animation is considered essential with respect to prefers-reduced-motion
        essential: true,
      });
    },
  },

  mounted() {
    this.makeMap();
  },
  data() {
    return {
      categories: [],
      macro_categories: [],
      places: [],

      __places: [],
      __sub_categories: [],
      __categories: [],
      index: null,
      sub_filter_per_categories: {},
      collaborazioniFilter: false,
      showSubFilters: false,
      sub_filters_checked: {},
      sub_filters: [],
      sub_categories_selected: {},
      showDetailMobile: false,
      viewSpallaMobile: false,

      defaultImg,
      filters: ["PASTICCERIA", "BAR", "RISTORANTE", "SERVIZI", "SHOP"],
      _filters: ["PASTICCERIA", "BAR", "RISTORANTE", "SERVIZI", "SHOP"],
      viewSpalla: false,
      isOpen: false,
      current: { imgUrls: [null], upload: [{ url: null }] },
      popup: false,
    };
  },
  components: {
    VueGallerySlideshow,
  },
};
</script>
<style>
.under:hover {
  text-decoration: underline;
}
#modal_footer {
  display: flex;
  justify-content: flex-end;
}
#modal_content {
  display: flex;
  flex-grow: 1;
    overflow-y:scroll;
  max-height:calc(100% - 152px)!important;

}
#modal_footer > .button {
  border: 0;
  border-radius: 32px;
  margin-left: 12px;
  max-width: 128px;
}
#modal_header {
  /* border-bottom: 1px solid #d9d9d9; */

  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: Poppins;
  color: black;
  font-size: 18px;
}
a.social:hover {
  fill: #658367;
}

#subfilters_main {
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow-y:hidden;
  justify-content: space-between;
  align-items: center;
}

#detailMobile {
    top:0px;
    height:calc(100vh - 0px)!important;
    width:100vw;
  position: fixed;
  
  z-index: 900000;
  background: white;
}
#placePopupMobile {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: 96px;
  width: calc(100vw - 24px);
  position: fixed;
  bottom: 12px;
  left: 12px;
  z-index: 700;
  border-radius: 12px;
  background: white;
}
#spallaMobile {
  position: absolute;
  top: 52px;
  left: 0;
  height: calc(100vh - 52px);

  /* overflow-y: scroll; */
  background: white;
  width: 100vw;
  z-index: 128;
}
#title-mobile {
  height: 52px;
  font-family: Antonio !important;
  font-size: 19px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  color: #171717;
  width: 100vw;
}
#mobile-mappa {
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;

  border-radius: 20px;
  opacity: 1;
  margin-left: 12px;
  width: 86px;
  height: 36px;
  font: var(--unnamed-font-style-normal) normal
    var(--unnamed-font-weight-normal) var(--unnamed-font-size-14) / 22px
    var(--unnamed-font-family-poppins);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-333a3d);
  text-align: left;
  font: normal normal normal 14px/22px Poppins;
  letter-spacing: 0px;
  color: #333a3d;
  opacity: 1;
  text-align: center;
  line-height: 36px;
  cursor: pointer;
  background: var(--unnamed-color-333a3d) 0% 0% no-repeat padding-box;
  background: #333a3d 0% 0% no-repeat padding-box;
  color: white;
}
#mobile-elenco[active] {
  bottom: 124px;
}
#mobile-elenco {
  position: fixed;
  z-index: 90000000;
  background: var(--unnamed-color-333a3d) 0% 0% no-repeat padding-box;
  background: #333a3d 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 0.8;
  bottom: 24px;
  left: calc(50% - 43px);
  color: white;
  font-family: var(--unnamed-font-family-poppins);
  letter-spacing: var(--unnamed-character-spacing-0);
  color: var(--unnamed-color-ffffff);
  text-align: left;

  text-align: center;
  line-height: 40px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #ffffff;

  width: 86px;
  height: 40px;
}

body {
-webkit-user-drag: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.main {

}
.popup {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}
.description { 
    word-wrap: break-word;
}
#map {

       position: fixed;
       overflow-y:hidden;
  width: calc(100vw - 0px);
  top: 52px;
    height: calc(100vh - 52px);

  /* height: calc(100vh - 104px)!important; */

}
.media {
  display: flex;
}
a:hover {
  text-decoration: underline !important;
}
.card {
  display: flex;
  background: white;

  border-radius: 12px;
  margin-bottom: 12px;
}
img {
  /* border-top-left-radius: 12px;
  border-bottom-left-radius: 12px; */
}
.place_name {
  font-weight: 500;
  font-family: "Cardo", serif;
  font-size: 19px;
  color: #171717;
  line-height: 40px;
}
.place_name_mobile {
  font-weight: 300;
  font-family: "Cardo", serif;
  font-size: 17px;
  color: #171717;
  line-height: 21px;
}
.place_name_left {
  font-weight: 500;
  font-family: "Cardo", serif;
  font-size: 24px;
  color: #171717;
  line-height: 52px;
}

.type-badge {
  margin-right: 6px;
  color: white;
  font-size: 10px;
  font-weight: 600;
  padding: 4px 7px;
  display: inline-block;
  text-transform: uppercase;
}

.type-badge[FOOD] {
  background: #d2a89b;
}
.type-badge[RISTORANTE] {
  background: #adc1cc;
}
.type-badge[BAR] {
  background: #808d4b;
}
.type-badge[ALTRO] {
  background: #c9c9c9;
}
.type-badge[SHOP] {
  background: #ba8ca4;
}
.type-badge[SERVIZI] {
  background: #e4e0a5;
}

.marker[FOOD] {
  background-image: url("marker-PASTICCERIA.svg");
}
.marker[RISTORANTE] {
  background-image: url("marker-RISTORANTE.svg");
}
.marker[BAR] {
  background-image: url("marker-BAR.svg");
}
.marker[SHOP] {
  background-image: url("marker-SHOP.svg");
}
.marker[SERVIZI] {
  background-image: url("marker-SERVIZI.svg");
}
.marker[ALTRO] {
  background-image: url("marker-OTHER.svg");
}
.marker {
  border-collapse: separate;
  z-index: 100;

  border-radius: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  cursor: pointer;
  box-shadow: 0px 0px 100px -10px #80f5f3;
  background-image: url("icona foglia.png");
  background-repeat: no-repeat;
  background-position: center; /* Center the image */
}

.indirizzo {
  color: grey;
  font-size: 12px;
}
.name {
  font-size: 14px;
}

#subHeader {
  display: none;
}

#leftPanelDesktop {
  position: absolute;
  height: calc(100vh - 104px);
  top: 104px;
  left: 0px;

  width: 624px;
  z-index: 12320;
  background: white;
}
@media only screen and (max-width: 1024px) {
  #filtriMobile {
    display: block;
  }
  #subfilters_modal {
    z-index: 900000000000000 !important;
    display: flex;
    flex-direction: column;
    margin-left: 0vw;
    padding: 24px;
    box-sizing:border-box;
          overflow-y:hidden;
    width: 100vw;
    height: 100vh;

    background: white;
  }
  #subfilters {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vh;
    width: 100vw;
      overflow-y:hidden;
    background: #70707080;

    z-index: 90000;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  }
  .filtriMobileIcon {
    background: transparent;
    z-index: 10000;
    display: block;
    right: 12px;
    top: 64px;
    position: absolute;
  }
  #placePopupDesktop {
    opacity: 0;
    position: absolute;
    height: calc(100vh - 52px);
    overflow-y: scroll;
    top: 52px;
    left: 0px;
    width: 624px;
    z-index: -1;
    background: white;
  }
  #map {
    position: fixed;
    top: 52px;
    height: calc(100vh - 52px);
  }
  #placePopupDesktop {
    width: 100vw;
  }
  .listButton {
    opacity: 0;
    pointer-events: none;
  }
  #subHeader {
    height: 0px !important;
    width: 100vw;
  }
  #header {
    background: white !important;
  }
}

@media only screen and (min-width: 1024px) {
  #filtriMobile {
    display: none;
  }
  #subfilters_modal {
    display: flex;
    flex-direction: column;
    margin-left: 25vw;
    margin-top: -64px;
    padding: 24px;
    width: 50vw;

    border-radius: 12px;
    background: white;
  }
  #subfilters {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100vh;
    width: 100vw;
    background: #70707080;

    z-index: 90000;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
  }
  .filtriMobileIcon {
    background: white;
    z-index: 10000;
    display: none;
    right: 12px;
    top: 64px;
    position: absolute;
  }
  #placePopupDesktop {
    position: absolute;
    height: calc(100vh - 104px);
    overflow-y: scroll;
    top: 104px;
    left: 0px;
    width: 624px;
    z-index: 12320;
    background: white;
  }
  #placePopupMobile {
    touch-action: none;
    overflow-y:hidden;
    opacity: 0; 
    position:fixed;
    bottom:12px;
    pointer-events: none!important;
  }
  #spallaMobile {
    height: 0px;
    display: none;
  }
  /* #title-mobile {
    opacity: 1;
    color: white;
  
    text-align: left;
    margin-left: 72px;
  } */
  #mobile-elenco {
    display: none;
    opacity: 0;
    z-index: -11;
    pointer-events: none;
  }
  #leftPanelDesktop {
    display: block;
  }
  #mobile-elenco {
    display: block !important;
  }
  #subHeader {
    display: block;
  }
  #header {
    display: block;
  }
  /* .marker {
    outline: 5px solid rgba(255, 0, 0, 0.364);
  }
  .marker:hover {
    outline: 7px solid rgba(255, 0, 0, 0.364);
  } */
}
#subHeader {
  border: 1px solid #eaeaea;
  display: flex;
  padding: 0px 12px;
  align-items: center;
  height: 52px;
  z-index: 400;
  position: absolute;
  top: 52px;
  background: white;
  width: 100vw;
  overflow-y: hidden;
}
.badge_av {
  cursor: pointer;
  margin-right: 12px;
  font-weight: 400 !important;
  padding: 4px 20px;
  line-height: 25px;
  border: 1px solid #d9d9d9;
  color: #333a3d !important;
  border-radius: 32px;

  font-size: 12px;
  text-transform: capitalize;
}
.badge {
  width: fit-content;

  cursor: pointer;
  margin-right: 12px;
  font-weight: 400 !important;
  padding: 4px 20px;
  line-height: 25px;
  border: 1px solid #d9d9d9;
  color: #333a3d !important;
  border-radius: 32px;

  white-space: nowrap;
  overflow: hidden;

  font-size: 12px;
  text-transform: capitalize;
  /* display: inline-block;
   line-break: strict; */
}
.badge:hover {
  background: #f2f2f2;
}
#listButtonOpen {
  left: 652px;
}
.listButton {
  display: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: flex;
  line-height: 36px;

  position: absolute;
  top: 124px;
  left: 24px;
  height: 36px;

  background: white;
}
.listButton_mobile {
  border-radius: 50%;
  display: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  display: flex;
  line-height: 36px;

  position: absolute;
  top: 124px;
  left: 24px;
  height: 36px;

  background: white;
}
.line {
  font-weight: 400;
  font-family: Poppins;
  color: #6a706e !important;
  font-size: 13px;
  line-height: 19px;
}
.popup_name {
  font-weight: 300;
  font-family: "Cardo", serif;
  font-size: 15px;
  color: #171717;
}
.collaborazione {
  width: 107px;
  height: 24px;
  line-height: 20px;

  background: #efefe3;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.collaborazione2 {
  padding: 12px 8px;
  background: #efefe3;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
.collaborazione > .title {
  color: #658368;
  font-weight: bold;
  text-transform: capitalize;
  font-size: 10px;
  line-height: 24px;
  text-align: center;
}
.badge[selected] {
  color: #333a3d;
  border-color: #333a3d;
}

/* .badge[tipologia="FOOD"][selected] {
  background: #d2a89b;
  color: white !important;
  border-color: #d2a89b;
}
.badge[tipologia="RISTORANTE"][selected] {
  background: #adc1cc;
  color: white !important;
  border-color: #adc1cc;
}
.badge[tipologia="BAR"][selected] {
  background: #808d4b;
  color: white !important;
  border-color: #808d4b;
}
.badge[tipologia="COLLABORAZIONI"][selected] {
  background: #d2a89b;
  color: white !important;
  border-color: #d2a89b;
}

.badge[tipologia="ALTRO"][selected] {
  background: #c9c9c9;
  color: white !important;
  border-color: #c9c9c9;
}

.badge[tipologia="SHOP"][selected] {
  background: #ba8ca4;
  color: white !important;
  border-color: #ba8ca4;
}

.badge[tipologia="SERVIZI"][selected] {
  background: #e4e0a5;
  color: white !important;
  border-color: #e4e0a5;
} */

img {
  object-fit: cover;
}
input[type="checkbox"] {
  margin-right: 8px;
  /* Add if not using autoprefixer */
  /* -webkit-appearance: none;
  appearance: none; */
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
}
::selection {
  background: #777777 !important;
}
.ant-switch-checked {
  background-color: #777777 !important;
}
.ant-checkbox-wrapper {
  margin: 4px 0px 4px !important;
  width: 192px;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #aaaaaa !important;
  border-color: #aaaaaa !important;
}

.aimg {
  height: 20px !important;
  opacity: 0.7;
}
.aimg {
  opacity: 1;
}
.image_masonry {
  margin: 2px;
  height: 128px;
  cursor: pointer;
}
.vgs {
  z-index: 100000000000 !important;
}
.vgs__container {
  margin-top: 128px !important;
  background-color: transparent !important;
}
.vgs__container > img {
}
.vgs__gallery {
  margin-left: auto !important;
  /* background:red; */
}
.vgs__gallery__container {
  /* background:blue;
  margin-left:auto;
  margin-right:auto;
  display:flex!important;
  justify-content: center; */
}
.x:hover > path {
  stroke: #777777;
}

.locale-link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.locale-link:hover {
  text-decoration: underline;
}
.filtri-buttons {
  opacity: 1 !important;
  min-width: 176px !important;
  max-width: 176px !important;
  padding: 12px !important;
}
.obscurer {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 36px;
  width: 100%;
  background: rgb(233, 233, 233);
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 0)),
    to(rgba(0, 0, 0, 1))
  );
}
.place_name > * {
  overflow: hidden;
  text-overflow: ellipsis; 
   white-space: nowrap;
}
.place_name_mobile {

}
.place_name_mobile > * {
 
}
.place_name {

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.detail {
  font-size: 15px !important;
  line-height: 28px !important;
}
.maplogo {
  width: 100%;
}
</style>
