<template>
  <div>
    <header>
      <div id="logo-mobile">
        <img
          id="imglogomobile"
          @click="$router.push('/home')"
          src="./vegan meetup ts_logo.png"
          style="height: 142px"
        />
        <div
          class="nomobile"
          style="
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 12px 12px 8px;
          "
        >
          <div>
            <a href="https://www.instagram.com/veganmeetupts/" target="_blank"
              ><img src="./imgs/social/icon-instagram.svg"
            /></a>
          </div>
          <div style="margin-left: 16px">
            <a
              href="https://www.facebook.com/veganmeetupTS/?locale=it_IT"
              target="_blank"
              ><img src="./imgs/social/icon-facebook.svg"
            /></a>
          </div>
        </div>
      </div>
    </header>
    <section class="vmuts-background">
      <div
        class="flex-row"
        style="height: 100vh; justify-content: space-around; width: 100%"
      >
        <div style="text-align: left">
          <div class="herotext">VEGAN MEETUP TRIESTE</div>
          <div class="vivatext">Viva Veg e po bon!</div>
        </div>
        <div class="polaroid">
          <img src="./home_imgs/Gruppo di maschere 143.png" />
        </div>
      </div>
    </section>
    <section>
      <div class="section-title">COSA FACCIAMO</div>
      <p class="herotext-mission" data-aos="fade-up">
        La nostra missione è aumentare l’offerta a base vegetale a Trieste e
        dintorni del 300% entro il 2025.
      </p>
      <div
        style="text-align: center; color: #d3a89a"
        class="saturday"
        data-aos="fade-up"
      >
        Come?
      </div>
    </section>
    <section class="flex-row" style="align-items: flex-start">
      <div>
        <div
          style="background: white; padding: 12px; text-align: left"
          data-aos="fade-up"
        >
          <span class="saturday dotted">1.</span
          ><span class="antonio">Vegan</span>
          <sup class="saturday-up" style="margin-left: 12px">meetup</sup>
        </div>
        <div
          class="text"
          style="
            margin-top: 64px;
            background: white;
            font-weight: 600;
            text-align: left;
            font-size: 17px;
          "
          data-aos="fade-up"
          data-aos-delay="50"
        >
          Picnic vegani, gratuiti e aperti a tutti* che rappresentano occasioni
          di socialità e condivisione per incoraggiare il networking e la
          creazione di una community locale.
        </div>
      </div>
      <div class="polaroid">
        <img src="./home_imgs/Gruppo di maschere 143.png" />
      </div>
    </section>
    <section
      class="row-noflex vmuts-background"
      style="position: relative; padding: 96px"
    >
      <div
        class="vmuts-background"
        style="
          top: 0px;
          height: 100%;
          position: absolute;
          z-index: -1;
          left: -1024px;
          width: calc(100vw - 324px + 1024px);
        "
      ></div>

      <div data-aos="fade-up" data-aos-delay="50">
        <span class="saturday dotted" style="font-size: 52px !important"
          >2.</span
        ><span class="antonio">SostieniTriesteVeg</span>
      </div>
      <div
        class=""
        style="
          text-align: center;
          font-size: 17px;
          font-weight: 800;
          letter-spacing: 2px;
          line-height: 35px !important;
          max-width: 640px !important;
          margin: auto;
          margin-top: 32px;
        "
        data-aos="fade-up"
      >
        <strong
          >Metaforicamente un faro per navigare le acque di una città ancora
          poco vegana; nella pratica una guida gratuita alle realtà
          vegan-friendly per guidare e incoraggiare le scelte alimentari e
          d’acquisto a base vegetale.</strong
        >
      </div>
      <div style="display: flex; justify-content: center">
        <div
          class="button"
          style="width: 256px; margin-top: 48px"
          @click="openMappa"
          data-aos="fade-down"
        >
          VAI ALLA MAPPA
        </div>
      </div>
    </section>
    <section class="flex-row" style="align-items: flex-start">
      <div style="text-align: left">
        <div data-aos="fade-up" data-aos-delay="50" class="fancy-title">
          <span class="saturday dotted">3.</span
          ><span class="antonio">Veganizzazioni</span>
        </div>
        <div
          class="text"
          style="
            text-align: left;
            font-size: 17px;
            font-weight: 600;
            margin-top: 64px;
          "
          data-aos="fade-up"
        >
          Collaborazioni con realtà locali per avviare, ampliare o incentivare
          la loro offerta vegetale tramite l’organizzazione di eventi, la
          creazione di nuovi piatti o menù, la selezione di prodotti per la
          vendita.
        </div>

        <div
          data-aos="fade-up"
          style="margin-top: 24px; width: 100%; text-align: left"
          data-aos-delay="150"
        >
          <div class="sublink">
            <div class="highlight"></div>
            <a target="_blank" href="/#/map?collaborazioni=true"
              >COLLABORAZIONI ATTIVE
            </a>
            <div class="darrow"></div>
          </div>
        </div>
      </div>
      <div class="polaroid">
        <img src="./home_imgs/Gruppo di maschere 143.png" />
      </div>
    </section>
    <section class="row-noflex">
      <div class="section-title">CHI SIAMO</div>
      <div class="flex-row" style="justify-content: space-between">
        <div class="polaroid">
          <img src="./home_imgs/Gruppo di maschere 143.png" />
        </div>
        <div style="margin-left: 96px">
          <p data-aos="fade-up" class="text">
            Nata nel 2020, dall’idea di Martina Pluda e Athina Krokos, Vegan
            Meetup Trieste è una giovane iniziativa, e la prima, con lo scopo di
            promuovere l'alimentazione a base vegetale e lo stile di vita vegano
            rendendolo più diffuso e accessibile, a Trieste e dintorni.
          </p>
          <br />
          <p data-aos="fade-up" class="text">
            Agiamo da catalizzatore per creare una community aperta e inclusiva
            di vegan*, vegetarian* e curios*. Facciamo da ponte con le realtà
            vegan-friendly locali per accelerare l'espansione dell'offerta
            vegetale nel nostro territorio.
          </p>
        </div>
      </div>
    </section>
    <section
      class="flex-row small vmuts-background"
      style="position: relative !important"
    >
      <div
        class="vmuts-background"
        style="
          top: 0px;
          height: 100%;
          position: absolute;
          z-index: -1;
          left: 960px;
          width: calc(200vw - 0px);
        "
      ></div>
      <div
        class="valori-title"
        style="position: absolute; left: -116px; top: 12px"
      >
        <strong>I NOSTRI VALORI</strong>
      </div>

      <div class="valore" style="position: absolute; left: 480px">
        <div class="vivatext" data-aos="fade-up" style="margin-bottom: 32px">
          Inclusione e rispetto
        </div>
        <div data-aos="fade-up">
          La nostra community è aperta e rispettosa delle persone con trascorsi
          e stili di vita diversi e crediamo che l’apertura al di fuori della
          “vegan bubble” ci porti più vicini all’ottenimento dei nostri
          obiettivi.
        </div>
      </div>
    </section>

    <section class="flex-row small" style="position: relative !important">
      <div class="valore">
        <div
          class="vivatext-pink"
          data-aos="fade-up"
          style="margin-bottom: 32px"
        >
          Empowerment e networking
        </div>
        <div data-aos="fade-up">
          Incoraggiamo le persone nel loro viaggio verso il veganismo in modo
          positivo, costruttivo e accessibile, senza giudicare e aiutandole a
          costruire quella rete di supporto di cui hanno bisogno.
        </div>
      </div>
    </section>
    <section class="flex-row small" style="position: relative !important">
      <div
        class="vmuts-background"
        style="
          top: 0px;
          height: 100%;
          position: absolute;
          z-index: -1;

          max-width: calc(200vw - 0px) !important;
          width: calc(200vw - 0px);
        "
      ></div>
      <div class="valore" style="position: absolute; left: 480px">
        <div
          class="vivatext-blue"
          data-aos="fade-up"
          style="margin-bottom: 32px"
        >
          Collaborazione e impatto locale
        </div>
        <div data-aos="fade-up">
          Collaborando con altri gruppi e professionisti orientati alla stessa
          missione, e lavorando a stretto contatto con business locali, cambiamo
          l’orientamento cittadino rispetto al tema.
        </div>
      </div>
    </section>
    <section class="row-noflex">
      <div class="section-title">CONTATTACI</div>

      <div>
        <div class="hero-text" data-aos="fade-up" style="margin: auto">
          Hai un’idea per una Trieste più vegana? Siamo sempre alla ricerca di
          idee innovative, o di scovare nuovi locali con opzioni vegane!
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: space-around;
              magin-top: 48px;
            "
          >
            <div class="button" style="margin-top: 48px" data-aos="fade-up">
              <a href="mailto:veganmeetupts@gmail.com">CONTATTACI</a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="row-noflex small" style="position: relative">
      <div
        class="vmuts-background"
        style="
          top: 0px;
          height: 100%;
          position: absolute;
          z-index: -1;
          left: -140vw;
          width: calc(200vw - 0px);
        "
      ></div>
      <div class="section-title">PARLANO DI NOI</div>
      <div style="width: 100%">
        <flickity ref="flickity" :options="flickityOptions">
          <div class="carousel-cell" v-for="(v, k) of parlanodinoi" :key="k">
            <div class="voice" style="margin: 12px; text-align: left">
              <div class="voice-title">
                <strong>{{ v.testata.toUpperCase() }}</strong> |
                {{ format(new Date(v.date), "dd/MM/yy") }}
              </div>
              {{ v.titolo }}
              <div>
                <a :href="'https://' + v.link" class="link">LINK</a>
              </div>
            </div>
          </div>
        </flickity>
      </div>
    </section>
    <section class="row-noflex small" style="height: 324px !important">
      <p
        data-aos="fade-up"
        style="
          color: #373737;

          font-size: 40px;
          width: 100%;
          text-align: center !important;
        "
      >
        Unisciti alla community
      </p>
      <p style="width: 100%; text-align: center">
        Seguici su instagram e facebook
      </p>
      <div class="social">
        <div style="display: flex; justify-content: center; padding: 32px">
          <div>
            <a href="https://www.instagram.com/veganmeetupts/" target="_blank">
              <img src="./imgs/social/icon-instagram.svg" style="height: 24px"
            /></a>
          </div>
          <div style="width: 32px"></div>
          <div>
            <a
              href="https://www.facebook.com/veganmeetupTS/?locale=it_IT"
              target="_blank"
            >
              <img src="./imgs/social/icon-facebook.svg" style="height: 24px"
            /></a>
          </div>
        </div>
      </div>
    </section>
    <section>FEED</section>
    <div id="footer">
      <div>
        <div>&copy; 2022 Vegan Meetup Trieste</div>
        <div style="height: 12px"></div>
        <div>
          Made with <span style="color: white">&hearts;</span> by
          <span style="color: white">Paolo</span> &
          <span style="color: white">Alice</span> - Illustrations by
          <span style="color: white">Petra</span> -
          <a href="/#/privacy" style="color: #fff !important"
            >Privacy e Cookie</a
          >
          -
          <a href="/#/terms" style="color: #fff !important"
            >Termini e condizioni</a
          >
        </div>
      </div>
      <div>
        <img
          @click="scrollTop"
          class="up"
          src="./up-arrow-svgrepo-com.svg"
          height="15"
          width="15"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";

Vue.directive("ripple", Ripple);
// import AOS from "aos";
// import "aos/dist/aos.css"; // You can also use <link> for styles
// // ..
// AOS.init();
// import "animate.css";
// @ is an alias to /src
import Vue from "vue";
import Flickity from "vue-flickity";
import axios from "axios";
import { format } from "date-fns";
export default {
  name: "Home",
  beforeCreate() {
    const isMobile = navigator.userAgentData.mobile;
    console.log(isMobile);
    if (isMobile) this.$router.push({ path: "/home-mobile" });
    //this.$router.push({ path: "/", hash: "" });
  },
  async mounted() {
    this.$router.replace({ query: null });
    const isMobile = navigator.userAgentData.mobile;
    console.log(isMobile);

    this.parlanodinoi = (await axios.get("/vmuts/api/parlanodinoi")).data;
    this.$nextTick(function () {
      // the magic
      this.$refs.flickity.rerender();
    });
    console.log(isMobile);

    //this.flickityOptions.prevNextButtons=false;

    this.$router.afterEach((to, from) => {
      this.mobilemenuopen = false;
    });
    this.observeSections();

    var elem = this.$el.querySelector(".gallery");

    window.onscroll = function () {
      myFunction();
    };

    // Get the header
    var header = this.$el.querySelector("#header");
    var headermobile = this.$el.querySelector("#headermobile");
    var logo = this.$el.querySelector("#logo");

    // Get the offset position of the navbar
    var sticky = header.offsetTop;

    // Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
    function myFunction() {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky-new");
        logo.classList.add("logo-small");
        headermobile.classList.add("stickymobile");
        //logo.style.height = "48px";
      } else {
        header.classList.remove("sticky-new");
        logo.classList.remove("logo-small");
        headermobile.classList.remove("stickymobile");
        //logo.style.height = "80px";
      }
    }
  },
  methods: {
    format,
    triggermobilemenu() {
      this.mobilemenuopen = !this.mobilemenuopen;
    },
    scrollTop() {
      window.scrollTo(0, 0);
    },
    observeSections() {
      try {
        this.sectionObserver.disconnect();
      } catch (error) {}

      const options = {
        rootMargin: "0px 0px",
        threshold: 0.25,
      };
      this.sectionObserver = new IntersectionObserver(
        this.sectionObserverHandler,
        options
      );

      // Observe each section
      const sections = document.querySelectorAll(".anchor");
      sections.forEach((section) => {
        this.sectionObserver.observe(section);
      });
    },
    sectionObserverHandler(entries) {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          const sectionId = entry.target.id;
          history.pushState(
            {},
            null,
            window.location.origin + "/#/home" + `#${sectionId}`
          );
          //window.location.hash = `#${sectionId}`;
          // Push sectionId to router here
          //this.$router.replace({  hash: `#${sectionId}` });
        }
      }
    },
    next() {
      this.$refs.flickity.next();
    },

    previous() {
      this.$refs.flickity.previous();
    },
    openMappa() {
      window.open(window.location.origin + "/#/map");
    },
  },
  data() {
    return {
      parlanodinoi: [],
      mobilemenuopen: false,
      sectionObserver: null,
      flickityOptions: {
        initialIndex: 0,
        cellAlign: "left",
        contain: true,
        prevNextButtons: true,
        pageDots: true,
        wrapAround: true,

        // any options from Flickity can be used
      },
    };
  },
  components: {
    Flickity,
  },
};
</script>
<style>
.fancy-title {
  display: flex;
  align-items: center;
}

header {
  position: fixed;
}
section {
  padding: 32px 0px;
  width: 100vw;
  text-align: center;
}
/* 
.flickity-prev-next-button.previous {
  left: -40px;
}
.flickity-prev-next-button.next {
  right: -40px;
  z-index: 100;
} */

html {
  scroll-behavior: smooth;
}

:root {
  --vmuts-background: #efefe3;
  --main-width: 960px;
  --page-height: 640px;
  --footer-background: #333a3d;
  --header-background: transparent;
  --footer-height: 96px;
  --header-height: 64px;
  --menu-font-family: Roboto;
  --menu-font-size: 13px;
  --menu-item-width: 128px;
  --hover-menu-color: red;
  --header-height-mobile: 40px;
}
.vmuts-background {
  background: var(--vmuts-background);
}
.hero-text {
  max-width: 760px;
  font-size: 37px !important;
  font-weight: 600 !important;
  line-height: 57px;
}
.section-title:before,
.section-title:after {
  background-color: #474747;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 24px;
  margin: 0px 12px;
}
.section-title {
  width: 100%;
  font-weight: 600;

  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  letter-spacing: 2px;
  margin-top: 32px;
  margin-bottom: 32px;
  text-transform: uppercase;
}
.flex-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  min-height: 480px;
  max-width: 960px;
  width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}
.row-noflex {
  min-height: 480px;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}
.flex-row > div {
  flex-grow: 1;
  max-width: 480px;
}

.polaroid {
  height: 480px;
  width: 480px;
  background: #fff;
  z-index: 3 !important;
  padding: 1.2rem;
  -webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.polaroid > img {
  object-fit: cover;
  aspect-ratio: 1/1;
  object-fit: cover;
  max-width: 100%;
  height: auto;
}
.valori-title {
  text-transform: capitalize;
  font-size: 29px;
  font-weight: 600;
}
.valore {
  padding: 48px;

  font-size: 21px;
  width: 760px !important;

  max-width: 640px !important;
  width: 640px;
}
.small {
  min-height: 396px !important;
}

.logo-small {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 64px !important;
}
.logo-small > img {
  height: 64px !important;
}
.sticky-new {
  position: fixed;

  width: 100%;
  /* -webkit-box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
  box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1); */
}

.mobile-block-white {
}

.link {
  font-size: 12px;
}
#mobilemenu {
  display: block;
  position: fixed;
  top: 70px;
  z-index: 4;
  background: white;
  width: 100vw;
}
#mobilemenu > div {
  margin: auto;
  text-align: center;
  margin-top: 48px;
}

/* .flickity-prev-next-button.previous {
  left: -40px;
}
.flickity-prev-next-button.next {
  right: -40px;
  z-index: 100;
} */

/* .flickity-button {
  background: transparent;
}

.flickity-prev-next-button {
  width: 32px;
  height: 32px;
}

.flickity-button-icon {
  fill: grey;
}

.flickity-button:disabled {
  display: none;
} */
html {
  scroll-behavior: smooth;
}
/* .parallax-container {
  position: absolute;
  background: red;
  right: 0;
  top: 0;
  width: 100%;
  height: 700px;
  overflow-x: hidden;
  overflow-y: scroll;
  perspective: 8px;
  perspective-origin: 0%;
}
.parallax-container > img {
  transform-origin: 0;
  transform: translateZ(3px) scale(0.625);
} */
.sublink:hover > .highlight {
  /* hardcoded */
  width: 104px;
}
.sublink:hover > .highlight2 {
  /* hardcoded */
  width: 160px;
}
.sublink:hover > .darrow {
  /* hardcoded */

  width: 52px;
}
.sublink:hover {
  position: relative;
  left: 44px;
  transition: left 1s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.darrow {
  margin-left: 12px;
  position: absolute;
  color: #484e4f;
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  transform: scale(1, 1);
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
  box-shadow: inset 0 0 0 1px;
  width: 52px;
  height: 5px;
  bottom: 2px;

  /* -webkit-transition: width 0.5s ease-in-out;
  -moz-transition: width 0.5s ease-in-out;
  -o-transition: width0.5s ease-in-out;
  transition: width 0.5s ease-in-out; */
}
.darrow::after {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 5px;
  height: 5px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  right: 0;
  bottom: -2px;
  color: #484e4f;
}

.sublink > .highlight {
  margin-top: 12px;
  display: inline-block;
  position: absolute;
  z-index: -1;
  background-color: #efefe3;
  height: 8px;

  width: 0%;
  transition: width 0.8s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: rotate(-0deg);
}

.sublink {
  left: 24px;
}
.voices {
  padding: 64px 0px;
  display: flex;
}
.voice-title {
  color: #6a706e;
  margin-bottom: 12px;
}

.dotted {
  margin-right: 8px;
  font-family: Saturday;

  font-size: 48px !important;
}
.vivamobile {
  font-family: Saturday;

  color: #808d4b;
}
.viva {
  font-family: Saturday;
  margin-left: 224px;
  font-size: 64px;
  color: #808d4b;
}

.sticky {
  position: fixed;
  padding: 0px 0px 0px 12px !important;

  top: 0;
  width: 100%;
  -webkit-box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
  box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
  background: white !important;
}

.stickymobile {
  position: fixed;

  top: 0px;
  width: 100%;
  -webkit-box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
  -moz-box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
  box-shadow: 0 2px 4px rgba(57, 63, 72, 0.1);
  background: white !important;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
}

h2 {
  font-size: 80px;
  font-weight: bold;
  font-weight: 800;
  font-family: Antonio !important;
  letter-spacing: 0px !important;
}
h3 {
  font-size: 64px;
  font-weight: normal;
}
.horizontal {
  display: flex;
}
.center {
  width: var(--main-width);
  text-align: left;
}
.shoulder {
  width: calc((100vw - var(--main-width)) / 2);
}
.page-height {
  height: var(--page-height) !important;
}
#left-shoulder {
  display: flex;

  width: calc((100vw - var(--main-width)) / 2);
}
#right-shoulder {
  display: flex;

  width: calc((100vw - var(--main-width)) / 2);
}
.right-shoulder {
  display: flex;

  width: calc((100vw - var(--main-width)) / 2);
}
.right-0 {
  right: 0;
}
.top-0 {
  top: 0;
}
.position-absolute {
  position: absolute;
}
#main {
  width: var(--main-width);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}
#hum {
  padding: 8px;
  border-radius: 50%;
}
#headermobile {
  height: 0px;
  display: none;

  padding: 0px 16px;
  background: transparent;
  z-index: 10000;
  width: 100vw;
  color: #333a3d;
}
#header {
  position: fixed;
  display: flex;
  padding: 0px 16px;

  background: transparent;
  z-index: 10000;
  width: 100vw;
  color: #333a3d;
}
#footer {
  font-weight: 600;
  padding: 24px 48px;
  font-size: 12px;
  color: #b5b5b5;
  background: var(--footer-background);
  display: flex;
  justify-content: space-between;
}

.page {
  height: var(--page-height);
  min-width: var(--main-width);
  max-width: var(--main-width);
}
#central-header {
  width: var(--main-width);
}
.row {
  display: flex;
  flex-grow: 1;
  align-items: center;

  padding: 12px;
}
._row {
  display: flex;
  flex-grow: 1;
  align-items: center;

  padding: 12px;
}
.white {
  background: white;
  padding: 96px 12px;
}
.nowhite {
  background: var(--vmuts-background);
  padding: 24px 12px;
}
.half {
  width: 50%;
  font-size: 14px;
  padding: 12px;
  box-sizing: border-box;
  letter-spacing: 1.25px !important;

  font-weight: 400;
  line-height: 30px !important;
}
.three-q {
  width: 66.6%;
  font-size: 14px;
  letter-spacing: 1.25px !important;

  font-weight: 400;
  line-height: 30px !important;
}

.button[black]:hover {
  background: black;

  font-weight: 600;
}
.button:hover {
  background: #333a3d;
  color:white!important;
  /* color: #333a3d;
  font-weight: 600;  */
}
.button:hover >a {

  color:white!important;
  /* color: #333a3d;
  font-weight: 600;  */
}
.button[black] {
  cursor: pointer;
  background: #333a3d;
  color: white;
}
.up:hover {
  cursor: pointer;
  height: 20px;
}
:root {
  --vmuts-background: #efefe3;
  --main-width: 960px;
  --page-height: 640px;
  --footer-background: #333a3d;
  --header-background: transparent;
  --footer-height: 96px;
  --header-height: 64px;
  --menu-font-family: Roboto;
  --menu-font-size: 13px;
  --menu-item-width: 128px;
  --hover-menu-color: red;
  --header-height-mobile: 40px;
}
.vmuts-background {
  /* z-index: -2;
  background: var(--vmuts-background); */
}
.offset-1 {
  position: relative;
  left: calc(100vw / 12);
}
.width-11 {
  width: calc(100vw * 11 / 12);
}
.width-1 {
  width: calc(100vw * 1 / 12);
}
.menu {
  box-sizing: border-box;
}
.buttons {
  box-sizing: border-box;

  height: 100%;
  align-items: center;
  display: flex;
}
.buttons > .button {
}
.menu > div {
  font-family: var(--menu-font-family);
  font-size: var(--menu-font-size);
  width: var(--menu-item-width);
  height: var(--header-height);
  display: flex;
  justify-content: center;
  align-items: center;
}
.menu > div:hover {
  cursor: pointer;
  color: var(--hover-menu-color);
}
.height-10 {
  height: calc(100vh / 12 * 10);
}
.home-image {
}
.left-border {
  box-sizing: border-box;

  border-bottom: 1px solid #ddd;
  width: calc(100% - 24px);
  line-height: 24px;
  height: 10px;
}

.hero-text {
  font-size: 48px;
  font-weight: 500;

  width: var(--main-width);
  text-align: center;
}
.antonio {
  font-family: AntonioBold;
  font-size: 45px;
  text-transform: uppercase;
}
@font-face {
  font-family: AntonioBold;
  src: url(Antonio-Bold.ttf);
}

p {
  font-weight: 600;
}

@font-face {
  font-family: Saturday;
  src: url(SaturdayScript.otf);
}
@font-face {
  font-family: "Poppins";
  src: url("./Poppins/Poppins-Thin.ttf");
  src: url("./Poppins/Poppins-Regular.ttf");
  src: url("./Poppins/Poppins-Bold.ttf");
  src: url("./Poppins/Poppins-ExtraBold.ttf");
  src: url("./Poppins/Poppins-ExtraLight.ttf");
  src: url("./Poppins/Poppins-SemiBold.ttf");
  src: url("./Poppins/Poppins-Medium.ttf");
  src: url("./Poppins/Poppins-Light.ttf");
}
@font-face {
  font-family: "Antonio";
  src: url("./Antonio/Antonio-Thin.ttf");
  src: url("./Antonio/Antonio-Regular.ttf");
  src: url("./Antonio/Antonio-Bold.ttf");

  src: url("./Antonio/Antonio-ExtraLight.ttf");
  src: url("./Antonio/Antonio-SemiBold.ttf");
  src: url("./Antonio/Antonio-Medium.ttf");
  src: url("./Antonio/Antonio-Light.ttf");
}

.vertical-small-space {
  height: 96px;
}
.vertical-smaller-space {
  height: 48px;
}
#hero {
  height: 520px;
  display: flex;
  flex-grow: 1;
}
.menu-item:hover {
  font-weight: 600;
  cursor: pointer;
}
.menu-item {
  width: 124px;
  margin-top:16px;
  text-align: center;


}
a {
  color: #333a3d !important;
}

h4 {
  font-weight: bold !important;
  font-weight: 600 !important;
  letter-spacing: 0.7px !important;
  font-size: 13px;
  text-transform: uppercase !important;
}
#headermobile {
  display: none;
}
@media screen and (max-width: 480px) {
  .mobile {
    display: block;
  }
  .nomobile {
    display: none !important;
  }
  html:not(.no-js) [data-aos-delay] {
    transition-delay: 0s !important;
  }
  html:not(.no-js) [data-aos] {
    transition-duration: 0ms !important;
  }
  html:not(.no-js) [data-aos] {
    -webkit-transform: translate3d(0, 0px, 0) !important;
    transform: translate3d(0, 0px, 0) !important;
  }

  a:link {
    text-decoration: none;
  }

  a:visited {
    text-decoration: none;
  }

  a:active {
    text-decoration: none;
  }

  .social {
  }

  .vertical-small-space {
    height: 48px !important;
  }
  .hero-text {
    font-size: 48px;
    font-weight: 350;
    margin: 24px 0px;
    width: 100%;

    text-align: center;
  }
  .valori {
    height: 512px;
  }

  .valori-text {
    text-align: center;
  }
  .v-space {
    height: 24px !important;
  }
  #headermobile {
    position: fixed;
    background: white;
    height: 72px;
    display: flex;
  }
  #heromobile {
    display: block;
  }
  .row {
    flex-direction: column;
  }
  .three-q {
    width: 100%;
    margin: 0px 24px;

    font-size: 14px;
  }
  /* comes into effect for screens less than or equal to 320 pixels */
  #header {
    display: flex;
    /* height: var(--header-height-mobile); */

    width: 100vw;
  }
  #menu {
    display: none !important;
    height: 100%;
  }
  .buttons {
    display: none !important;
  }
  #social {
    display: none !important;
  }
  .shoulder {
    display: none !important;
  }

  #hero {
    display: none !important;
    height: 0 !important;
  }
  #logo > img {
  }
  .smallLogo {
    height: 24px !important;
  }

  .hero-text {
    font-size: 18pt !important;
  }
  .row {
    flex-direction: column !important;
  }
  .half {
    width: 100%;
    margin: 0px 24px;
    font-size: 14px;
  }

  /* .vmuts-background {
    background: white;
  } */
  .valori {
    height: 512px;
  }
  .center {
    /* margin-top: 24px;
    margin-left: 24px;
    margin-right: 24px; */
    width: 100% !important;
    /* text-align: center !important; */
  }
  .center > .titoletto {
    font-family: Poppins;
    width: 100vw !important;
    text-align: center;
    position: relative;
  }

  .center > .mobile {
    text-transform: uppercase;
    font-size: 15px;
    text-align: center;
    font-weight: 800;
    font-size: 13px;
  }

  /* .titoletto:before {
    content: "";
    display: block;
    width: 12px;
    height: 1px;
    background: #000;
    left: -24px;
    top: calc(50% - 1px);
    position: absolute;
  } */
  .backvmutsmobile {
    background: red;
    background: #efefe3;
  }
}

/* @media screen and (min-width: 481px) {
  .nomobile {
    display: block;
  }
  .mobile {
    display: none;
  }
  #headermobile {
    display: none;
  }

} */
.gallery {
  background: #eee;
}

.gallery-cell {
  width: 66%;

  background: #8c8;
  counter-increment: gallery-cell;
}

/* cell number */
.gallery-cell:before {
  display: block;
  text-align: center;
  content: counter(gallery-cell);
  line-height: 200px;
  font-size: 80px;
  color: white;
}
</style>