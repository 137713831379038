import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue';
const store = require("../store")
Vue.use(VueRouter)

import Map from '../views/Map.vue';
import Home from '../views/HomeNew.vue';
import Privacy from '../views/Privacy.vue';
import Terms from '../views/Terms.vue';
import HomeMobile from '../views/HomeMobile.vue';

const routes = [{
  path: '/map',
  name: 'Map',
  component: Map
},
{
  path: '/home',
  name: 'Home',
  component: Home,
  alias: '/'
},
{
  path: '/privacy',
  name: 'Privacy',
  component: Privacy,
  alias: '/privacy'
},
{
  path: '/terms',
  name: 'Terms',
  component: Terms,
  alias: '/terms'
},
// {
//   path: '/home-mobile',
//   name: 'HomeMobile',
//   component: HomeMobile,
//   alias: '/'
// },
{
  path: '/login',
  name: 'Login',
  component: Login
},
{
  path: '/admin',
  name: 'admin',
  component: function () {
    return import('../views/Admin.vue')
  }
},
{
  path: '/admin/luoghi',
  name: 'luoghi',
  component: function () {
    return import('../views/AddPlace.vue')
  }
},
{
  path: '/admin/eventi',
  name: 'eventi',
  component: function () {
    return import('../views/AddEvent.vue')
  }
},
{
  path: '/admin/sub_categories',
  name: 'categories',
  component: function () {
    return import('../views/AddCategory.vue')
  }
},
{
  path: '/admin/parlanodinoi',
  name: 'parlanodinoi',
  component: function () {
    return import('../views/AddParlano.vue')
  }
}
]



const router = new VueRouter({

  routes,
  afterEach() {

  },
  scrollBehavior(to, from, savedPosition) {

    if (to.hash == from.hash) return { x: 0, y: 0 }

    //https://router.vuejs.org/guide/advanced/scroll-behavior.html
    if (to.hash) {
      return { selector: to.hash }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 }
    }



  }

})
router.beforeEach((to, from, next) => {
  
    if (to.query.map) {
        router.push("/map")
    }

  if (!router.app.$store.state.loggedIn && to.name == "admin") {
    router.push("/login")
  } else
    next()

})

export default router
